<template>
  <nav id="sidebar" :class="isMenuCollapsed ? 'sidebar collapsed' : 'sidebar'">
    <simplebar ref="simplebar" data-simplebar-auto-hide="false" :key="simplebarKey">
      <router-link class="sidebar-brand" to="/">
        <img src="./../../assets/institucional/img/logo-horus-temp.png" alt="Rede de bases de dadods">
      </router-link>
      <ul class="sidebar-nav">
        <li class="sidebar-item icon-dashboard " :class="$route.name === 'AdminHome' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin"><span class="align-middle">Dashboard</span></router-link>
        </li>
        <li class="sidebar-item icon-especie" :class="$route.name === 'AdminEspecies' || $route.name === 'AdminEspeciesForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/especies"><span class="align-middle">{{ $t('label.especies') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-ocorrencia" :class="$route.name === 'AdminOcorrencias' || $route.name === 'AdminOcorrenciasForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/ocorrencias"><span class="align-middle">{{ $t('label.ocorrencias') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-ocorrencia" :class="$route.name === 'AdminOcorrenciasApp' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/ocorrencias-app"><span class="align-middle">{{ $t('label.ocorrenciasApp') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-referencia" :class="$route.name === 'AdminReferencias' || $route.name === 'AdminReferenciasForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/referencias"><span class="align-middle">{{ $t('label.referencias') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-colaborar" :class="$route.name === 'AdminColaboradores' || $route.name === 'AdminColaboradoresForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/colaboradores"><span class="align-middle">{{ $t('label.colaboradores') }}</span></router-link>
        </li>
        <li class="sidebar-item icon-projetos" :class="$route.name === 'AdminProjetos' || $route.name === 'AdminProjetosForm' ? 'active' : ''">
          <router-link class="sidebar-link" to="/admin/projetos"><span class="align-middle">{{ $t('label.projetos') }}</span></router-link>
        </li>
        <template v-if="user.perfilUser === 'administrator' || user.perfilUser === 'coralsoluser' || user.perfilUser === 'coralsoladmin'">
          <li class="sidebar-item icon-exportar" :class="$route.name === 'AdminCoralSol' || $route.name === 'AdminCoralSolForm'  ? 'active' : ''">
            <a data-target="#exportar" data-toggle="collapse" class="sidebar-link" :class="{ 'collapsed': !expandedCoralSol }" :aria-expanded="expandedCoralSol" @click="toggleCollapse('expandedCoralSol')">
              <span class="align-middle">{{ $t('label.coralSol') }}</span>
            </a>
            <ul id="coralsol" class="sidebar-dropdown list-unstyled collapse exportar" :class="{ 'show': expandedCoralSol }" data-parent="#sidebar">
              <li class="sidebar-item"  :class="$route.name === 'Localidade' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/coralsol/localidade"><span class="align-middle">{{ $t('coralSol.localidade') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Dafor' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/coralsol/dafor"><span class="align-middle">{{ $t('coralSol.dafor') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Ocorrencias' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/coralsol/ocorrencias"><span class="align-middle">{{ $t('coralSol.ocorrencias') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Manejos' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/coralsol/manejos"><span class="align-middle">{{ $t('coralSol.manejos') }}</span></router-link>
              </li>
            </ul>
          </li>
        </template>
        <template v-if="user.perfilUser === 'administrator'">
          <li class="sidebar-item">
            <hr>
          </li>
          <li class="sidebar-item icon-usuario" :class="$route.name === 'AdminUsuarios' || $route.name === 'AdminUsuariosForm' ? 'active' : ''">
            <router-link class="sidebar-link" to="/admin/usuarios"><span class="align-middle">{{ $t('label.usuarixs') }}</span></router-link>
          </li>
          <li class="sidebar-item icon-taxonomia" :class="$route.name === 'AdminTaxonomia' ? 'active' : ''">
            <router-link class="sidebar-link" to="/admin/taxonomia"><span class="align-middle">{{ $t('label.taxonomia') }}</span></router-link>
          </li>
          <li class="sidebar-item icon-exportar" :class="$route.name === 'Fichas' || $route.name === 'FichasMMA' || $route.name === 'FichasMMA2' ? 'active' : ''">
            <a data-target="#exportar" data-toggle="collapse" class="sidebar-link" :class="{ 'collapsed': !expanded }" :aria-expanded="expanded" @click="toggleCollapse('expanded')">
              <span class="align-middle">{{ $t('label.exportarDados') }}</span>
            </a>
            <ul id="exportar" class="sidebar-dropdown list-unstyled collapse exportar" :class="{ 'show': expanded }" data-parent="#sidebar">
              <li class="sidebar-item"  :class="$route.name === 'Consultas' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/exportar/consultas"><span class="align-middle">Consultas</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Fichas' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/exportar/fichas"><span class="align-middle">Fichas técnicas</span></router-link>
              </li>
            </ul>
          </li>
          <li class="sidebar-item icon-settings" :class="$route.name === 'AreaProtegida' || $route.name === 'SysEmails' || $route.name === 'SysEmailGroup' ? 'active' : ''">
            <a data-target="#config" data-toggle="collapse" class="sidebar-link" :class="{ 'collapsed': !expandedConfi }" :aria-expandedConfi="expandedConfi" @click="toggleCollapse('expandedConfi')">
              <span class="align-middle">{{ $t('label.configuracoes') }}</span>
            </a>
            <ul id="config" class="sidebar-dropdown list-unstyled collapse exportar" :class="{ 'show': expandedConfi }" data-parent="#sidebar">
              <li class="sidebar-item"  :class="$route.name === 'AreaProtegida' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/area-protegida"><span class="align-middle">{{ $t('label.areaProtegida') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Municipio' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/municipio"><span class="align-middle">{{ $t('label.municipio') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'Emails' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/emails"><span class="align-middle">{{ $t('label.emails') }}</span></router-link>
              </li>
              <li class="sidebar-item"  :class="$route.name === 'GrupoEmails' ? 'active' : ''">
                <router-link class="sidebar-link submenu" to="/admin/grupo-emails"><span class="align-middle">{{ $t('label.grupoDeEmails') }}</span></router-link>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </simplebar>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import simplebar from 'simplebar-vue'
export default {
  name: 'AdminSidebar',
  components: {
    simplebar
  },
  data () {
    return {
      expanded: false,
      expandedCoralSol: false,
      expandedConfi: false,
      simplebarKey: 0
    }
  },
  computed: {
    ...mapState(['isMenuCollapsed', 'user'])
  },
  methods: {
    toggleCollapse (menu) {
      this[menu] = !this[menu]
      this.simplebarKey++
    }
  }
}
</script>
