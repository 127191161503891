<template>
  <div class="container-fluid p-0">
    <PageTitle
      :title="pageTitle"
      :titleSave="pendingAnalysis ? $t('ocorrencias.salvar') : $t('ocorrencias.salvar')"
      :isAdd="false"
      @onSave="save"
      @onCancel="cancel"
      @onExcluir="excluir"
      :nameEdit="nameEdit"
      :pendingAnalysis="pendingAnalysis"
      :hasExportCSV="this.$route.params.id !== this.$actionNew"
      @onExportCSV="exportCSV"
    />

    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-3">
            <div class="align-self-center">
              <div class="row">
                <div class="col-md-6">
                  <div class="mt-3">
                    <label for="occurrence-locality" class="form-label">{{ $t('localidade.pageTitle') }}</label>
                    <v-select
                      :appendToBody="true"
                      :filterable="false"
                      @search="getLocalities"
                      :options="localitiesList"
                      v-model="item.locality_id"
                      @input="onLocalityChange"
                      label="name"
                      index="locality_id"
                      :reduce="r => r.locality_id"
                      :clearable="false"
                      class="wf-select vue-select"
                    >
                      <template v-slot:selected-option="option">
                        {{ `${option.name}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.name}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{ `${$t('message.noResultsFor')} ` }}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div
                    class="mt-3"
                    :class="{ 'pending_analysis': pendingAnalysis && item.log_update && item.date !== item.log_update.date }"
                  >
                    <label for="occurrence-date" class="form-label">{{ $t('ocorrencias.date') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="occurrence-date"
                      v-model="item.date"
                      v-mask="'##/##/####'"
                      :class="{ 'is-invalid': $v.item.date.$dirty && $v.item.date.$error }"
                    />
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div
                    class="mt-3"
                    :class="{ 'pending_analysis': pendingAnalysis && item.log_update && item.observer !== item.log_update.observer }"
                  >
                    <label for="occurrence-observer" class="form-label">{{ $t('ocorrencias.observer') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="occurrence-observer"
                      v-model="item.observer"
                      :class="{ 'is-invalid': $v.item.observer.$dirty && $v.item.observer.$error }"
                    />
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-depth" class="form-label">{{ $t('ocorrencias.depth') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="occurrence-depth"
                      v-model.number="item.depth"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-height" class="form-label">{{ $t('ocorrencias.height') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="occurrence-height"
                      v-model.number="item.height"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-width" class="form-label">{{ $t('ocorrencias.width') }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="occurrence-width"
                      v-model.number="item.width"
                    />
                  </div>
                </div>
                <div class="col-md-6 border-start">
                  <div class="mt-3">
                    <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                    <v-select
                      :appendToBody="true"
                      :filterable="false"
                      @search="getContacts"
                      :options="contactsList"
                      v-model="item.contact_id"
                      label="surname"
                      index="contact_id"
                      :reduce="r => r.contact_id"
                      :clearable="false"
                      class="wf-select vue-select"
                    >
                      <template v-slot:selected-option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{ `${$t('message.noResultsFor')} ` }}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-access" class="form-label">{{ $t('ocorrencias.access') }}</label>
                    <div id="occurrence-access">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="access-accessible"
                          value="acessivel"
                          v-model="item.access"
                        />
                        <label class="form-check-label" for="access-accessible">
                          {{ $t('ocorrencias.acessivel') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="access-difficult"
                          value="dificil"
                          v-model="item.access"
                        />
                        <label class="form-check-label" for="access-difficult">
                          {{ $t('ocorrencias.dificil') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="access-inaccessible"
                          value="inacessivel"
                          v-model="item.access"
                        />
                        <label class="form-check-label" for="access-inaccessible">
                          {{ $t('ocorrencias.inacessivel') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-coverage" class="form-label">{{ $t('ocorrencias.coverage') }}</label>
                    <div id="occurrence-coverage">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="coverage-30"
                          value="1"
                          v-model="item.coverage"
                        />
                        <label class="form-check-label" for="coverage-30">
                          Até 30%
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="coverage-60"
                          value="2"
                          v-model="item.coverage"
                        />
                        <label class="form-check-label" for="coverage-60">
                          31%-60%
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="coverage-100"
                          value="3"
                          v-model="item.coverage"
                        />
                        <label class="form-check-label" for="coverage-100">
                          61%-100%
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-geomorphology" class="form-label">{{ $t('ocorrencias.geomorphology') }}</label>
                    <select
                      class="form-select"
                      id="occurrence-geomorphology"
                      v-model="item.geomorphology"
                    >
                      <option value="" disabled selected></option>
                      <option value="toca-fenda">{{ $t('ocorrencias.tocaFenda') }}</option>
                      <option value="matacao-paredao">{{ $t('ocorrencias.matacaoParedao') }}</option>
                      <option value="gruta-caverna">{{ $t('ocorrencias.grutaCaverna') }}</option>
                      <option value="rochas-pequenas-medias">{{ $t('ocorrencias.rochasPequenasMedias') }}</option>
                      <option value="lage">{{ $t('ocorrencias.lage') }}</option>
                    </select>
                  </div>
                  <div class="col-md-6 border-start">
                    <div class="mt-3">
                      <label for="occurrence-subaquaticaPhoto" class="form-label">{{ $t('ocorrencias.subaquaticaPhoto') }}</label>
                      <div v-if="showSubaquaticaPhoto" class="bg-dark container-fluid">
                        <img :src="subaquaticaPhotoUrl" alt="Foto Subaquática" width="200" height="200" class="img-fluid mx-auto d-block" />
                      </div>
                      <input class="form-control" type="file" accept="image/*" id="occurrence-subaquaticaPhoto" @change="onFileChange('subaquaticaPhoto')" />
                    </div>
                    <div class="mt-3">
                      <label for="occurrence-superficiePhoto" class="form-label">{{ $t('ocorrencias.superficiePhoto') }}</label>
                      <div v-if="showSuperficiePhoto" class="bg-dark container-fluid">
                        <img :src="superficiePhotoUrl" alt="Foto Superfície" width="200" height="200" class="img-fluid mx-auto d-block" />
                      </div>
                      <input class="form-control" type="file" accept="image/*" id="occurrence-superficiePhoto" @change="onFileChange('superficiePhoto')" />
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="occurrence-observation" class="form-label">{{ $t('ocorrencias.observation') }}</label>
                    <textarea
                      class="form-control"
                      id="occurrence-observation"
                      rows="4"
                      v-model="item.observation"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mt-3">
                    <div id="map" ref="mapContainer" style="height: 500px;"></div>
                    <label for="occurrence-spot-coords" class="form-label">{{ $t('ocorrencias.spotCoords') }}</label>
                    <textarea
                      class="form-control"
                      id="occurrence-spot-coords"
                      rows="4"
                      v-model="item.spot_coords"
                      readonly
                      :class="{ 'is-invalid': $v.item.spot_coords.$dirty && $v.item.spot_coords.$error }"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                    <button class="btn btn-secondary mt-2" @click="undoLastPoint">{{ $t('ocorrencias.undoLastPoint') }}</button>
                    <button class="btn btn-primary  mt-2  ml-2" @click="toggleManualInput">
                      {{ showManualInput ? $t('ocorrencias.cancelar') : $t('ocorrencias.adicionarCoordenada') }}
                    </button>
                    <div v-if="showManualInput" class="d-flex flex-column flex-grow-1">
                      <input
                        v-model="manualCoord"
                        type="text"
                        class="form-control mt-2"
                        placeholder="[[-27.28, -48.35], [-27.29, -48.36]]" />
                      <small class="text-muted">Latitude, Longitude</small>
                      <button class="btn btn-success mt-2 ml-2" @click="addManualCoordinate">{{ $t('ocorrencias.confirmar') }}</button>
                      <span v-if="coordError" class="text-danger mt-2 ml-2">{{ coordError }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminModalReview :form="item" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CoralsolOccurrenceForm',
  components: {
    PageTitle,
    AdminModalReview
  },
  props: {
    startUpload: Boolean
  },
  data () {
    return {
      pageTitle: `${this.$t('ocorrencias.pageTitle')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      item: {
        date: this.$options.filters.formatDate(new Date()),
        observer: '',
        depth: null,
        height: null,
        width: null,
        access: '',
        coverage: null,
        geomorphology: '',
        spot_coords: '',
        observation: '',
        locality_id: null,
        contact_id: null,
        subaquatica_photo: '',
        superficie_photo: ''
      },
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      localitiesList: [],
      contactsList: [],
      map: null,
      drawnItems: null,
      polyline: null,
      points: [],
      startMarker: null,
      endMarker: null,
      localityPolyline: null,
      file: null,
      spotPhotoUrl: null,
      subaquaticaPhotoUrl: null,
      superficiePhotoUrl: null,
      showSpotPhoto: false,
      showSubaquaticaPhoto: false,
      showSuperficiePhoto: false,
      changeImage: false,
      showManualInput: false,
      manualCoord: '',
      coordError: ''
    }
  },
  validations: {
    item: {
      date: {
        required,
        validDate (value) {
          return /^\d{2}\/\d{2}\/\d{4}$/.test(value)
        }
      },
      observer: { required },
      spot_coords: { required }
    }
  },
  watch: {
    'item.subaquatica_photo' (newValue) {
      if (newValue) {
        this.upload('subaquaticaPhoto')
      }
    },
    'item.superficie_photo' (newValue) {
      if (newValue) {
        this.upload('superficiePhoto')
      }
    }
  },
  computed: {
    pendingAnalysis () {
      return this.item.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    }
  },
  methods: {
    onFileChange (type) {
      const inputElement = event.target
      const file = inputElement.files[0]
      if (file) {
        const timestamp = Date.now()
        const fileNameParts = file.name.split('.')
        const extension = fileNameParts.pop()
        const baseName = fileNameParts.join('.')
        const newFileName = `${baseName}_${timestamp}.${extension}`

        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (event) => {
          const img = new Image()
          img.src = event.target.result

          img.onload = () => {
            const maxDimension = 1200
            let width = img.width
            let height = img.height

            if (width > maxDimension || height > maxDimension) {
              if (width > height) {
                height = Math.floor(height * (maxDimension / width))
                width = maxDimension
              } else {
                width = Math.floor(width * (maxDimension / height))
                height = maxDimension
              }
            }

            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height

            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)

            canvas.toBlob((blob) => {
              this.file = new File([blob], newFileName, { type: file.type })

              if (type === 'subaquaticaPhoto') {
                this.subaquaticaPhotoUrl = URL.createObjectURL(this.file)
                this.item.subaquatica_photo = newFileName
                this.showSubaquaticaPhoto = true
              } else if (type === 'superficiePhoto') {
                this.superficiePhotoUrl = URL.createObjectURL(this.file)
                this.item.superficie_photo = newFileName
                this.showSuperficiePhoto = true
              }
            }, file.type, 0.92)
          }
        }
      }
    },
    getImage: function (type) {
      const photoField = type === 'subaquatica_photo' ? 'subaquatica_photo' : 'superficie_photo'
      if (this.item[photoField]) {
        axios.get(`/Upload/UploadImageCoralSol/${this.item.occurrence_id}/${this.item[photoField]}`)
          .then(resp => {
            if (resp.data) {
              const url = `data:image/*;base64,${resp.data}`
              if (type === 'spotPhoto') {
                this.url = url
                this.showImage = true
              } else if (type === 'subaquatica_photo') {
                this.subaquaticaPhotoUrl = url
                this.showSubaquaticaPhoto = true
              } else if (type === 'superficie_photo') {
                this.superficiePhotoUrl = url
                this.showSuperficiePhoto = true
              }
            }
          }).catch(error => {
            console.error('Erro ao carregar a imagem:', error)
            this.$toasted.global.error(this.$t('message.errorLoadingImage'))
          })
      }
    },
    upload: function (type) {
      let photoKey = ''
      let file = null

      switch (type) {
        case 'subaquaticaPhoto':
          photoKey = 'subaquatica_photo'
          file = this.file
          break
        case 'superficiePhoto':
          photoKey = 'superficie_photo'
          file = this.file
          break
      }

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('fileName', this.item[photoKey])

        axios
          .post(`/upload/UploadImageCoralSol/${this.item.occurrence_id}/${this.item[photoKey]}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(() => {
            this.$toasted.global.saved()
          })
          .catch((error) => {
            console.error(`Erro ao fazer upload da imagem (${type}):`, error)
            this.$toasted.global.error(this.$t('message.errorUploadingImage'))
          })
      } else {
        this.$toasted.global.saved()
      }
    },
    modalValuesSimple (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'date':
          newValue = this.item.date
          oldValue = this.item.log_update ? this.item.log_update.date : ''
          this.modalValuesSimple(newValue, oldValue, label)
          break
        case 'observer':
          newValue = this.item.observer
          oldValue = this.item.log_update ? this.item.log_update.observer : ''
          this.modalValuesSimple(newValue, oldValue, label)
          break
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.item.$invalid) {
        return false
      }
      if (this.action === this.$actionEdit) {
        await axios.put('/CoralsolOccurrence', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      } else {
        this.item.ococcurrence_id = null
        await axios.post('/CoralsolOccurrence', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/coralsol/ocorrencias')
      }
    },
    exportCSV () {
      const contato = this.contactsList.find(c => c.contact_id === this.item.contact_id)
      const localidade = this.localitiesList.find(l => l.locality_id === this.item.locality_id)
      const baseURL = axios.defaults.baseURL.replace(/\/$/, '')
      const subaquaticaUrl = this.item.subaquatica_photo
        ? `${baseURL}/Upload/UploadImageCoralSol/${this.item.occurrence_id}/${this.item.subaquatica_photo}`
        : ''

      const superficieUrl = this.item.superficie_photo
        ? `${baseURL}/Upload/UploadImageCoralSol/${this.item.occurrence_id}/${this.item.superficie_photo}`
        : ''

      const dados = {
        date: this.item.date,
        observer: this.item.observer,
        locality: localidade ? localidade.name : '',
        contact: contato ? `${contato.surname}, ${contato.name}` : '',
        depth: this.item.depth,
        height: this.item.height,
        width: this.item.width,
        access: this.item.access,
        coverage: this.item.coverage,
        geomorphology: this.item.geomorphology,
        observation: this.item.observation,
        spot_coords: this.item.spot_coords,
        subaquatica_photo: subaquaticaUrl,
        superficie_photo: superficieUrl
      }

      const labels = {
        date: 'Data',
        observer: 'Observador',
        locality: 'Localidade',
        contact: 'Colaborador',
        depth: 'Profundidade',
        height: 'Altura',
        width: 'Largura',
        access: 'Acesso',
        coverage: 'Cobertura',
        geomorphology: 'Geomorfologia',
        observation: 'Observações',
        spot_coords: 'Coordenadas',
        subaquatica_photo: 'Link Foto Subaquática',
        superficie_photo: 'Link Foto Superfície'
      }

      const headers = Object.keys(dados).map(key => labels[key]).join(';')
      const values = Object.values(dados).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(';')

      const csv = `${headers}\n${values}`

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'ocorrencia.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async excluir () {
      if (confirm(this.$t('message.confirmDel'))) {
        await axios.delete(`/CoralsolOccurrence/${this.item.occurrence_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    getLocalities (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.name = search
        loading(true)
        axios.get('/CoralSolLocality/getallcombo', { params })
          .then(response => {
            this.localitiesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        axios.get('/CoralSolLocality/getallcombo', { params })
          .then(response => {
            this.localitiesList = response.data.items
          })
      }
    },
    getContacts (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
            loading(false)
          })
      } else if (!search) {
        params.contact_id = this.item.contact_id
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
          })
      }
    },
    async getById (id) {
      this.$store.dispatch('showPreload')
      const response = await axios.get(`/CoralsolOccurrence/${id}`)
      this.item = response.data
      this.$nextTick(() => {
        this.initializeMap()
      })
      if (this.item.locality_id) {
        this.focusLocality(this.item.locality_id)
      }
      if (this.item.contact_id) {
        this.getContacts()
      }
      if (this.item.subaquatica_photo) {
        this.getImage('subaquatica_photo')
      }
      if (this.item.superficie_photo) {
        this.getImage('superficie_photo')
      }
    },
    initializeMap () {
      if (this.map) {
        return
      }

      this.map = L.map(this.$refs.mapContainer).setView([-27.2833, -48.3683], 13)

      L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 18,
        attribution: 'Tiles © Esri'
      }).addTo(this.map)

      this.drawnItems = L.featureGroup().addTo(this.map)

      if (this.item.spot_coords) {
        const coords = JSON.parse(this.item.spot_coords)
        this.points = coords
        this.polyline = L.polyline(coords, { color: 'red' }).addTo(this.drawnItems)
        this.updateMarkers()
        const bounds = this.polyline.getBounds()
        this.map.fitBounds(bounds)
      } else {
        this.map.setView([-27.2833, -48.3683], 13)
      }

      this.map.on('click', this.onMapClick)
    },
    async onLocalityChange (value) {
      if (value) {
        await this.focusLocality(value)
      }
    },
    async focusLocality (localityId) {
      const locality = await axios.get(`/CoralSolLocality/${localityId}`)
      const coords = JSON.parse(locality.data.coords_local)
      if (this.localityPolyline) {
        this.drawnItems.removeLayer(this.localityPolyline)
      }
      this.localityPolyline = L.polyline(coords, { color: 'blue' }).addTo(this.drawnItems)
      this.map.fitBounds(this.localityPolyline.getBounds())
    },
    onMapClick (e) {
      if (this.points.length === 1) {
        this.points.pop()
        this.drawnItems.removeLayer(this.polyline)
      }
      const latlng = e.latlng
      this.points.push([latlng.lat, latlng.lng])
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.spot_coords = JSON.stringify(this.points)
    },
    updateMarkers () {
      if (this.startMarker) {
        this.drawnItems.removeLayer(this.startMarker)
      }
      if (this.endMarker) {
        this.drawnItems.removeLayer(this.endMarker)
      }

      if (this.points.length > 0) {
        this.startMarker = L.marker(this.points[0], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }

      if (this.points.length > 1) {
        this.endMarker = L.marker(this.points[this.points.length - 1], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }
    },
    undoLastPoint () {
      this.points.pop()
      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.spot_coords = JSON.stringify(this.points)
    },
    toggleManualInput () {
      this.showManualInput = !this.showManualInput
      this.manualCoord = ''
      this.coordError = ''
    },
    addManualCoordinate () {
      let parsed

      try {
        parsed = JSON.parse(this.manualCoord)

        const isValidFormat = Array.isArray(parsed) &&
          parsed.every(coord =>
            Array.isArray(coord) &&
            coord.length === 2 &&
            typeof coord[0] === 'number' &&
            typeof coord[1] === 'number' &&
            coord[0] >= -90 && coord[0] <= 90 &&
            coord[1] >= -180 && coord[1] <= 180
          )

        if (!isValidFormat) {
          throw new Error()
        }
      } catch (e) {
        this.coordError = 'Formato inválido. Use: [[lat, lng], [lat, lng], ...]'
        return
      }

      // Adiciona os pontos ao array principal
      this.points.push(...parsed)

      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }

      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.map.fitBounds(this.polyline.getBounds())
      this.item.spot_coords = JSON.stringify(this.points)
      this.toggleManualInput()
    }
  },
  created () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('ocorrencias.pageTitle')} > ${this.$t('label.editando')}:`
      this.getById(this.$route.params.id)
    } else {
      this.$nextTick(() => {
        this.initializeMap()
      })
    }
    this.getLocalities()
    this.getContacts()
  }
}
</script>

<style scoped>
#map {
  height: 500px;
}
</style>
