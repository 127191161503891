<template>
  <div>
    <div class="table-responsive full_table mt-0">
      <table class="table caption-top">
        <thead class="bg_table_header">
          <tr>
            <th class="col-md-1 py-3">ID<button class="bt_order_asc bt_order" @click="orderBy(1)"></button><button class="bt_order_desc bt_order" @click="orderBy(-1)"></button></th>
            <th class="col-md-2 py-3">{{ $t('localidade.name') }}<button class="bt_order_asc bt_order" @click="orderBy(2)"></button><button class="bt_order_desc bt_order" @click="orderBy(-2)"></button></th>
            <th class="col-md-2 py-3">{{ $t('localidade.date') }}<button class="bt_order_asc bt_order" @click="orderBy(4)"></button><button class="bt_order_desc bt_order" @click="orderBy(-4)"></button></th>
            <th class="col-md-2 py-3">{{ $t('localidade.conservationUnit') }}<button class="bt_order_asc bt_order" @click="orderBy(5)"></button><button class="bt_order_desc bt_order" @click="orderBy(-5)"></button></th>
            <th class="col-md-2 py-3 pr-4 text-right">{{ $t('label.acoes') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.countList === 0"><td colspan="6" class="text-center">{{ $t('message.noRecords') }}</td></tr>
          <tr v-else v-for="item in items" :key="item.locality_id">
            <td class="py-3">{{item.locality_id}}</td>
            <td class="py-3">{{item.name}}</td>
            <td class="py-3">{{item.date}}</td>
            <td class="py-3">{{item.conservation_unit}}</td>
            <td class="py-3">
              <ul class="acoes text-right pr-1">
                <li>
                  <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                </li>
                <li>
                  <a @click="action($actionDelete, item)" v-if="$store.state.user && ($store.state.user.perfilUser === 'administrator' || $store.state.user.perfilUser === 'coralsoladmin')"><span class="apagar"></span></a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination v-on:searchTable="search()"/>
  </div>
</template>
<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
export default {
  name: 'CoralsolLocalidadeTable',
  components: { TablePagination },
  props: {
    items: Array
  },
  data: function () {
    return {
      dataOrderBy: {}
    }
  },
  methods: {
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        // if (this.$route.name === 'AdminHome') {
        //   this.$store.state.dashboard.currentPage = this.$store.state.currentPage
        // }
        this.$router.push({ name: 'CoralsolLocalidadeForm', params: { id: item.locality_id } })
      }
    },
    delete: function (item) {
      axios.delete(`/CoralSolLocality/${item.locality_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$emit('search')
    },
    orderBy: function (num) {
      this.$store.state.dataOrderBy = {}
      if (num === 1) {
        this.$store.state.dataOrderBy.OrderByIDAcs = true
        this.$store.state.dataOrderBy.OrderByIDDesc = !this.$store.state.dataOrderBy.OrderByIDAcs
      }
      if (num === -1) {
        this.$store.state.dataOrderBy.OrderByIDDesc = true
        this.$store.state.dataOrderBy.OrderByIDAcs = !this.$store.state.dataOrderBy.OrderByIDDesc
      }
      if (num === 2) {
        this.$store.state.dataOrderBy.OrderByNameAcs = true
        this.$store.state.dataOrderBy.OrderByNameDesc = !this.$store.state.dataOrderBy.OrderByNameAcs
      }
      if (num === -2) {
        this.$store.state.dataOrderBy.OrderByNameDesc = true
        this.$store.state.dataOrderBy.OrderByNameAcs = !this.$store.state.dataOrderBy.OrderByNameDesc
      }
      if (num === 3) {
        this.$store.state.dataOrderBy.OrderByLocalCoordsAcs = true
        this.$store.state.dataOrderBy.OrderByLocalCoordsDesc = !this.$store.state.dataOrderBy.OrderByLocalCoordsAcs
      }
      if (num === -3) {
        this.$store.state.dataOrderBy.OrderByLocalCoordsDesc = true
        this.$store.state.dataOrderBy.OrderByLocalCoordsAcs = !this.$store.state.dataOrderBy.OrderByLocalCoordsDesc
      }
      if (num === 4) {
        this.$store.state.dataOrderBy.OrderByDateAcs = true
        this.$store.state.dataOrderBy.OrderByDateDesc = !this.$store.state.dataOrderBy.OrderByDateAcs
      }
      if (num === -4) {
        this.$store.state.dataOrderBy.OrderByDateDesc = true
        this.$store.state.dataOrderBy.OrderByDateAcs = !this.$store.state.dataOrderBy.OrderByDateDesc
      }
      if (num === 5) {
        this.$store.state.dataOrderBy.OrderByConservationUnitAcs = true
        this.$store.state.dataOrderBy.OrderByConservationUnitDesc = !this.$store.state.dataOrderBy.OrderByConservationUnitAcs
      }
      if (num === -5) {
        this.$store.state.dataOrderBy.OrderByConservationUnitDesc = true
        this.$store.state.dataOrderBy.OrderByConservationUnitAcs = !this.$store.state.dataOrderBy.OrderByConservationUnitDesc
      }
      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
