<template>
  <div class="container-fluid p-0">
    <PageTitle
      :title="pageTitle"
      :titleSave="pendingAnalysis ? $t('dafor.save') : $t('dafor.save')"
      :isAdd="false"
      @onSave="save"
      @onCancel="cancel"
      @onExcluir="excluir"
      :nameEdit="nameEdit"
      :pendingAnalysis="pendingAnalysis"
      :hasExportCSV="this.$route.params.id !== this.$actionNew"
      @onExportCSV="exportCSV"
    />

    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-3">
            <div class="align-self-center">
              <div class="row">
                <div class="col-md-6">
                  <!-- Existing Fields -->
                  <div class="mt-3">
                    <label for="add-ocorrencias-localidade" class="form-label">{{ $t('coralSol.localidade') }}</label>
                    <button class="btn" type="button" @click="analysis($t('coralSol.localidade'), 'relLocalitiesSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                    <v-select
                      :appendToBody="true"
                      :filterable="false"
                      @search="getLocalities"
                      :options="localitiesList"
                      v-model="item.locality_id"
                      label="name"
                      index="locality_id"
                      :reduce="r => r.locality_id"
                      :clearable="false"
                      class="wf-select vue-select"
                      :class="{ 'is-invalid': $v.item.locality_id.$dirty && !$v.item.locality_id.required }"
                    >
                      <template v-slot:selected-option="option">
                        {{ `${option.name}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.name}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && item.log_update && item.date !== item.log_update.date }">
                    <label for="dafor-date" class="form-label">{{ $t('dafor.date') }}</label>
                    <input type="text" class="form-control" id="dafor-date" v-model="item.date" v-mask="'##/##/####'" :class="{ 'is-invalid': $v.item.date.$dirty && $v.item.date.$error }" />
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3" :class="{ 'pending_analysis': pendingAnalysis && item.log_update && item.observer !== item.log_update.observer }">
                    <label for="dafor-observer" class="form-label">{{ $t('dafor.observer') }}</label>
                    <input type="text" class="form-control" id="dafor-observer" v-model="item.observer" :class="{ 'is-invalid': $v.item.observer.$dirty && $v.item.observer.$error }" />
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3">
                    <label for="dafor-horizontal-visibility" class="form-label">
                      {{ $t('dafor.horizontalVisibility') }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="dafor-horizontal-visibility"
                      v-model.number="item.horizontal_visibility"
                      :class="{ 'is-invalid': $v.item.horizontal_visibility.$dirty && !$v.item.horizontal_visibility.required }"
                    />
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3">
                    <label for="dafor-min-depth" class="form-label">
                      {{ $t('dafor.minDepth') }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="dafor-min-depth"
                      v-model.number="item.min_depth"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="dafor-max-depth" class="form-label">
                      {{ $t('dafor.maxDepth') }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="dafor-max-depth"
                      v-model.number="item.max_depth"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="dafor-method" class="form-label">{{ $t('dafor.method') }}</label>
                    <div id="dafor-method">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="dafor-method-scuba"
                          value="scuba"
                          v-model="item.method"
                          :class="{ 'is-invalid': $v.item.method.$dirty && !$v.item.method.required }"
                        />
                        <label class="form-check-label" for="dafor-method-scuba">
                          Scuba
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="dafor-method-snorkeling"
                          value="snorkeling"
                          v-model="item.method"
                          :class="{ 'is-invalid': $v.item.method.$dirty && !$v.item.method.required }"
                        />
                        <label class="form-check-label" for="dafor-method-snorkeling">
                          Snorkeling
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="dafor-bathymetric-zone" class="form-label">{{ $t('dafor.bathymetricZone') }}</label>
                    <div id="dafor-bathymetric-zone">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="bathymetric-zone-entremares"
                          value="entremares"
                          v-model="item.bathymetric_zone"
                          :class="{ 'is-invalid': $v.item.bathymetric_zone.$dirty && !$v.item.bathymetric_zone.required }"
                        />
                        <label class="form-check-label" for="bathymetric-zone-entremares">
                          {{ $t('dafor.entremares') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="bathymetric-zone-raso"
                          value="raso"
                          v-model="item.bathymetric_zone"
                          :class="{ 'is-invalid': $v.item.bathymetric_zone.$dirty && !$v.item.bathymetric_zone.required }"
                        />
                        <label class="form-check-label" for="bathymetric-zone-raso">
                          {{ $t('dafor.raso') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="bathymetric-zone-fundo"
                          value="fundo"
                          v-model="item.bathymetric_zone"
                          :class="{ 'is-invalid': $v.item.bathymetric_zone.$dirty && !$v.item.bathymetric_zone.required }"
                        />
                        <label class="form-check-label" for="bathymetric-zone-fundo">
                          {{ $t('dafor.fundo') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                    <button class="btn" type="button" @click="analysis($t('label.colaborador'), 'relContactsSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                    <v-select
                      :appendToBody="true"
                      :filterable="false"
                      @search="getContacts"
                      :options="contactsList"
                      v-model="item.contact_id"
                      label="surname"
                      index="contact_id"
                      :reduce="r => r.contact_id"
                      :clearable="false"
                      class="wf-select vue-select"
                      :class="{ 'is-invalid': $v.item.contact_id.$dirty && !$v.item.contact_id.required }"
                    >
                      <template v-slot:selected-option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3">
                    <label for="dafor-number-of-divers" class="form-label">{{ $t('dafor.numberOfDivers') }}</label>
                    <input type="number" class="form-control" id="dafor-number-of-divers" v-model.number="item.number_of_divers" :class="{ 'is-invalid': $v.item.number_of_divers.$dirty && !$v.item.number_of_divers.required }"/>
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                  <div class="mt-3">
                    <label for="dafor-observation" class="form-label">{{ $t('dafor.observation') }}</label>
                    <textarea class="form-control" id="dafor-observation" rows="4" v-model="item.observation" :class="{ 'is-invalid': $v.item.observation.$dirty && !$v.item.observation.required }"></textarea>
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                  </div>
                </div>
                <div class="col-md-6 border-start">
                  <div class="mt-3">
                    <label for="dafor-number-of-visual-transects" class="form-label">{{ $t('dafor.numberOfVisualTransects') }}</label>
                    <input type="number" class="form-control" id="dafor-number-of-visual-transects" v-model.number="item.number_of_visual_transects" @input="validatePositiveInteger" />
                  </div>
                  <!-- Dynamic Inputs -->
                  <div v-for="(transect, index) in transects" :key="index" class="mt-3">
                    <label :for="'transect-' + index" class="form-label">
                      {{ $t('dafor.visualTransect') }} #{{ index + 1 }}
                    </label>
                    <select :id="'transect-' + index" v-model="transect.value" class="form-control">
                      <option value="10">D</option>
                      <option value="8">A</option>
                      <option value="6">F</option>
                      <option value="4">O</option>
                      <option value="2">R</option>
                      <option value="0">Ausente</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mt-3">
                    <div id="map" ref="mapContainer" style="height: 500px;"></div>
                    <label for="dafor-coords" class="form-label">{{ $t('dafor.daforCoords') }}</label>
                    <textarea class="form-control" id="dafor-coords" rows="4" v-model="item.dafor_coords" readonly
                    :class="{ 'is-invalid': $v.item.dafor_coords.$dirty && !$v.item.dafor_coords.required }"></textarea>
                    <div class="invalid-feedback">{{ $t('message.requiredField') }}</div>
                    <button class="btn btn-secondary mt-2" @click="undoLastPoint">{{ $t('dafor.undoLastPoint') }}</button>
                    <button class="btn btn-primary  mt-2  ml-2" @click="toggleManualInput">
                      {{ showManualInput ? $t('dafor.cancelar') : $t('dafor.adicionarCoordenada') }}
                    </button>
                    <div v-if="showManualInput" class="d-flex flex-column flex-grow-1">
                      <input
                        v-model="manualCoord"
                        type="text"
                        class="form-control mt-2"
                        placeholder="[[-27.28, -48.35], [-27.29, -48.36]]" />
                      <small class="text-muted">Latitude, Longitude</small>
                      <button class="btn btn-success mt-2 ml-2" @click="addManualCoordinate">{{ $t('dafor.confirmar') }}</button>
                      <span v-if="coordError" class="text-danger mt-2 ml-2">{{ coordError }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminModalReview :form="item" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CoralsolDaforForm',
  components: {
    PageTitle,
    AdminModalReview
  },
  data () {
    return {
      pageTitle: `${this.$t('dafor.pageTitle')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      item: {
        date: this.$options.filters.formatDate(new Date()),
        observer: '',
        horizontal_visibility: null,
        bathymetric_zone: '',
        min_depth: null,
        max_depth: null,
        method: '',
        number_of_divers: null,
        number_of_visual_transects: null,
        observation: '',
        dafor_coords: '',
        contact_id: null,
        locality_id: null
      },
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      localitiesList: [],
      contactsList: [],
      map: null,
      drawnItems: null,
      polyline: null,
      points: [],
      startMarker: null,
      endMarker: null,
      localityPolyline: null,
      transects: [],
      showManualInput: false,
      manualCoord: '',
      coordError: '' // Array to store dynamic transects
    }
  },
  validations: {
    item: {
      date: {
        required,
        validDate (value) {
          return /^\d{2}\/\d{2}\/\d{4}$/.test(value)
        }
      },

      bathymetric_zone: { required },
      observation: { required },
      contact_id: { required },
      locality_id: { required },
      observer: { required },
      horizontal_visibility: { required },
      number_of_divers: { required },
      number_of_visual_transects: { required },
      method: { required },
      dafor_coords: { required },
      transects: {
        required () {
          return this.transects.every(transect => transect.value !== null)
        }
      }
    }
  },
  computed: {
    pendingAnalysis () {
      return this.item.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    }
  },
  methods: {
    updateTransects () {
      // Garante que this.item.number_of_visual_transects é um número válido
      const desiredLength = parseInt(this.item.number_of_visual_transects, 10) || 0

      // Ajusta o tamanho do array `transects`
      if (this.transects.length < desiredLength) {
        // Adiciona novos itens completamente novos
        for (let i = this.transects.length; i < desiredLength; i++) {
          this.transects.push({ value: null }) // Sempre insere um novo objeto
        }
      } else if (this.transects.length > desiredLength) {
        // Remove os últimos itens até alcançar o tamanho desejado
        this.transects.splice(desiredLength)
      }
    },
    loadSelectedOptions () {
      if (this.item.dafor_value) {
        const loadedTransects = this.item.dafor_value.split(',').map(value => ({ value: value.trim() }))
        // Ajusta quantidade conforme number_of_visual_transects
        this.transects = []
        for (let i = 0; i < this.item.number_of_visual_transects; i++) {
          if (loadedTransects[i]) {
            this.$set(this.transects, i, { value: loadedTransects[i].value }) // Reativo
          } else {
            this.$set(this.transects, i, { value: null }) // Reativo
          }
        }
      }
    },
    validatePositiveInteger (event) {
      // Converte o valor para inteiro ou zera se não for número
      const value = parseInt(this.item.number_of_visual_transects, 10)
      if (isNaN(value) || value < 0) {
        this.item.number_of_visual_transects = ''
      } else {
        this.item.number_of_visual_transects = value
      }
      this.updateTransects() // Chama a lógica associada ao @input
    },
    modalValuesSimple (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'date':
          newValue = this.item.date
          oldValue = this.item.log_update ? this.item.log_update.date : ''
          this.modalValuesSimple(newValue, oldValue, label)
          break
        case 'observer':
          newValue = this.item.observer
          oldValue = this.item.log_update ? this.item.log_update.observer : ''
          this.modalValuesSimple(newValue, oldValue, label)
          break
      }
    },
    validateDate () {
      this.$v.item.date.$touch()
    },
    async save () {
      this.$v.$touch()
      if (this.$v.item.$invalid) {
        return false
      }
      this.item.dafor_value = this.transects.map(transect => transect.value).join(',')
      // Transforming the types to ensure they match the backend expectations
      const payload = {
        ...this.item,
        horizontal_visibility: Number(this.item.horizontal_visibility),
        min_depth: this.item.min_depth ? Number(this.item.min_depth) : null,
        max_depth: this.item.max_depth ? Number(this.item.max_depth) : null,
        number_of_divers: Number(this.item.number_of_divers),
        number_of_visual_transects: Number(this.item.number_of_visual_transects),

        transects: this.transects.map(transect => transect.value) // Include transects in the payload
      }

      this.item.log_update = JSON.stringify(this.item.log_update)
      if (this.action === this.$actionEdit) {
        await axios.put('/CoralsolDafor', payload)
          .then(() => {
            this.$toasted.global.saved()
            this.item.log_update = JSON.parse(this.item.log_update)
            this.cancel()
          })
      } else {
        await axios.post('/CoralsolDafor', payload)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/coralsol/dafor')
      }
    },
    exportCSV () {
      const contato = this.contactsList.find(c => c.contact_id === this.item.contact_id)
      const localidade = this.localitiesList.find(l => l.locality_id === this.item.locality_id)

      const dados = {
        date: this.item.date,
        observer: this.item.observer,
        locality: localidade ? localidade.name : '',
        contact: contato ? `${contato.surname}, ${contato.name}` : '',
        horizontal_visibility: this.item.horizontal_visibility,
        min_depth: this.item.min_depth,
        max_depth: this.item.max_depth,
        method: this.item.method,
        bathymetric_zone: this.item.bathymetric_zone,
        number_of_divers: this.item.number_of_divers,
        number_of_visual_transects: this.item.number_of_visual_transects,
        transects: this.transects.map(t => t.value).join(', '),
        observation: this.item.observation,
        dafor_coords: this.item.dafor_coords
      }

      const labels = {
        date: 'Data',
        observer: 'Observador',
        locality: 'Localidade',
        contact: 'Colaborador',
        horizontal_visibility: 'Visibilidade Horizontal',
        min_depth: 'Profundidade Mínima',
        max_depth: 'Profundidade Máxima',
        method: 'Método',
        bathymetric_zone: 'Zona Batimétrica',
        number_of_divers: 'Número de Mergulhadores',
        number_of_visual_transects: 'Número de Transectos Visuais',
        transects: 'Transectos Visuais (valores)',
        observation: 'Observação',
        dafor_coords: 'Coordenadas DAFOR'
      }

      const headers = Object.keys(dados).map(key => labels[key]).join(';')
      const values = Object.values(dados).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(';')

      const csv = `${headers}\n${values}`

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'formulario_dafor.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async excluir () {
      if (confirm(this.$t('message.confirmDel'))) {
        await axios.delete(`/CoralsolDafor/${this.item.dafor_id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    getContacts (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
            loading(false)
          })
      } else if (!search) {
        params.contact_id = this.item.contact_id
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
          })
      }
    },
    getLocalities (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.name = search
        loading(true)
        axios.get('/CoralSolLocality/getallcombo', { params })
          .then(response => {
            this.localitiesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        axios.get('/CoralSolLocality/getallcombo', { params })
          .then(response => {
            this.localitiesList = response.data.items
          })
      }
    },
    async handleLocalitySelection () {
      if (this.item.locality_id) {
        const response = await axios.get(`/CoralSolLocality/${this.item.locality_id}`)
        const locality = response.data

        if (locality.coords_local) {
          const coords = JSON.parse(locality.coords_local)
          this.localityPolyline = L.polyline(coords, { color: 'blue' }).addTo(this.drawnItems)
          this.map.fitBounds(this.localityPolyline.getBounds())
        }
      }
    },
    getById (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/CoralsolDafor/${id}`)
        .then(response => {
          this.item = response.data
          this.transects = []
          this.$nextTick(() => {
            this.initializeMap()
            this.loadSelectedOptions()
            this.getContacts()
          })
        })
    },
    initializeMap () {
      if (this.map) {
        return
      }

      this.map = L.map(this.$refs.mapContainer).setView([-27.2833, -48.3683], 8)

      L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 18,
        attribution: 'Tiles © Esri'
      }).addTo(this.map)

      this.drawnItems = L.featureGroup().addTo(this.map)

      if (this.item.dafor_coords) {
        const coords = JSON.parse(this.item.dafor_coords)
        this.points = coords
        this.polyline = L.polyline(coords, { color: 'red' }).addTo(this.drawnItems)
        this.updateMarkers()
        const bounds = this.polyline.getBounds()
        this.map.fitBounds(bounds)
      } else {
        this.map.setView([-27.2833, -48.3683], 8)
      }

      this.map.on('click', this.onMapClick)
    },
    onMapClick (e) {
      const latlng = e.latlng
      this.points.push([latlng.lat, latlng.lng])
      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.dafor_coords = JSON.stringify(this.points)
    },
    updateMarkers () {
      if (this.startMarker) {
        this.drawnItems.removeLayer(this.startMarker)
      }
      if (this.endMarker) {
        this.drawnItems.removeLayer(this.endMarker)
      }

      if (this.points.length > 0) {
        this.startMarker = L.marker(this.points[0], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }

      if (this.points.length > 1) {
        this.endMarker = L.marker(this.points[this.points.length - 1], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }
    },
    undoLastPoint () {
      this.points.pop()
      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.dafor_coords = JSON.stringify(this.points)
    },
    toggleManualInput () {
      this.showManualInput = !this.showManualInput
      this.manualCoord = ''
      this.coordError = ''
    },
    addManualCoordinate () {
      let parsed

      try {
        parsed = JSON.parse(this.manualCoord)

        const isValidFormat = Array.isArray(parsed) &&
          parsed.every(coord =>
            Array.isArray(coord) &&
            coord.length === 2 &&
            typeof coord[0] === 'number' &&
            typeof coord[1] === 'number' &&
            coord[0] >= -90 && coord[0] <= 90 &&
            coord[1] >= -180 && coord[1] <= 180
          )

        if (!isValidFormat) {
          throw new Error()
        }
      } catch (e) {
        this.coordError = 'Formato inválido. Use: [[lat, lng], [lat, lng], ...]'
        return
      }

      // Adiciona os pontos ao array principal
      this.points.push(...parsed)

      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }

      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.map.fitBounds(this.polyline.getBounds())
      this.item.dafor_coords = JSON.stringify(this.points)
      this.toggleManualInput()
    }
  },
  watch: {
    'item.locality_id' () {
      this.handleLocalitySelection()
    }
  },
  created () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('dafor.pageTitle')} > ${this.$t('label.editando')}:`
      this.getById(this.$route.params.id)
    } else {
      this.$nextTick(() => {
        this.initializeMap()
      })
    }
    this.getContacts()
    this.getLocalities()
  }
}
</script>

<style scoped>
#map {
  height: 500px;
}
</style>
