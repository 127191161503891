<template>
  <div>
    <div class="table-responsive full_table mt-0">
      <table class="table caption-top">
        <thead class="bg_table_header">
          <tr>
            <th class="col-md-1 py-3">ID<button class="bt_order_asc bt_order" @click="orderBy(1)"></button><button class="bt_order_desc bt_order" @click="orderBy(-1)"></button></th>
             <th class="col-md-2 py-3">{{ $t('ocorrencias.date') }}<button class="bt_order_asc bt_order" @click="orderBy(2)"></button><button class="bt_order_desc bt_order" @click="orderBy(-2)"></button></th>
            <th class="col-md-2 py-3">{{ $t('ocorrencias.depth') }}<button class="bt_order_asc bt_order" @click="orderBy(3)"></button><button class="bt_order_desc bt_order" @click="orderBy(-3)"></button></th>
            <th class="col-md-2 py-3">{{ $t('coralSol.localidade') }}<button class="bt_order_asc bt_order" @click="orderBy(5)"></button><button class="bt_order_desc bt_order" @click="orderBy(-5)"></button></th>
            <th class="col-md-2 py-3">{{ $t('ocorrencias.access') }}<button class="bt_order_asc bt_order" @click="orderBy(6)"></button><button class="bt_order_desc bt_order" @click="orderBy(-6)"></button></th>
            <th class="col-md-2 py-3 pr-4 text-right">{{ $t('label.acoes') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.countList === 0"><td colspan="9" class="text-center">{{ $t('message.noRecords') }}</td></tr>
          <tr v-else v-for="item in items" :key="item.occurrence_id">
            <td class="py-3">{{item.occurrence_id}}</td>
            <td class="py-3">{{item.date}}</td>
            <td class="py-3">{{item.depth}}</td>
            <td class="py-3">{{item.locality_name}}</td>
            <td class="py-3">{{ $t(`ocorrencias.${item.access}`) }}</td>
            <td class="py-3">
              <ul class="acoes text-right pr-1">
                <li>
                  <a @click="action($actionEdit, item)"><span class="editar"></span></a>
                </li>
                <li>
                  <a @click="action($actionDelete, item)" v-if="$store.state.user && ($store.state.user.perfilUser === 'administrator' || $store.state.user.perfilUser === 'coralsoladmin')"><span class="apagar"></span></a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TablePagination v-on:searchTable="search()"/>
  </div>
</template>
<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'
export default {
  name: 'CoralsolOcorrenciasTable',
  components: { TablePagination },
  props: {
    items: Array
  },
  data: function () {
    return {
      dataOrderBy: {}
    }
  },
  methods: {
    action: function (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        this.$router.push({ name: 'CoralsolOcorrenciasForm', params: { id: item.occurrence_id } })
      }
    },
    delete: function (item) {
      axios.delete(`/CoralsolOccurrence/${item.occurrence_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search: function () {
      this.$emit('search')
    },
    orderBy: function (num) {
      this.$store.state.dataOrderBy = {}
      if (num === 1) {
        this.$store.state.dataOrderBy.OrderByIDAcs = true
        this.$store.state.dataOrderBy.OrderByIDDesc = !this.$store.state.dataOrderBy.OrderByIDAcs
      }
      if (num === -1) {
        this.$store.state.dataOrderBy.OrderByIDDesc = true
        this.$store.state.dataOrderBy.OrderByIDAcs = !this.$store.state.dataOrderBy.OrderByIDDesc
      }
      if (num === 2) {
        this.$store.state.dataOrderBy.OrderByDateAcs = true
        this.$store.state.dataOrderBy.OrderByDateDesc = !this.$store.state.dataOrderBy.OrderByDateAcs
      }
      if (num === -2) {
        this.$store.state.dataOrderBy.OrderByDateDesc = true
        this.$store.state.dataOrderBy.OrderByDateAcs = !this.$store.state.dataOrderBy.OrderByDateDesc
      }
      if (num === 3) {
        this.$store.state.dataOrderBy.OrderByDepthAcs = true
        this.$store.state.dataOrderBy.OrderByDepthDesc = !this.$store.state.dataOrderBy.OrderByDepthAcs
      }
      if (num === -3) {
        this.$store.state.dataOrderBy.OrderByDepthDesc = true
        this.$store.state.dataOrderBy.OrderByDepthAcs = !this.$store.state.dataOrderBy.OrderByDepthDesc
      }
      if (num === 5) {
        this.$store.state.dataOrderBy.LocalityIDAcs = true
        this.$store.state.dataOrderBy.LocalityIDDesc = !this.$store.state.dataOrderBy.LocalityIDAcs
      }
      if (num === -5) {
        this.$store.state.dataOrderBy.LocalityIDDesc = true
        this.$store.state.dataOrderBy.LocalityIDAcs = !this.$store.state.dataOrderBy.LocalityIDDesc
      }
      if (num === 6) {
        this.$store.state.dataOrderBy.OrderByAccessAcs = true
        this.$store.state.dataOrderBy.OrderByAccessDesc = !this.$store.state.dataOrderBy.OrderByAccessAcs
      }
      if (num === -6) {
        this.$store.state.dataOrderBy.OrderByAccessDesc = true
        this.$store.state.dataOrderBy.OrderByAccessAcs = !this.$store.state.dataOrderBy.OrderByAccessDesc
      }
      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
