export const es = {
  message: {
    requiredField: 'Campo obligatorio',
    noRecords: '¡No se encontraron registros!',
    confirmDel: '¿Estás seguro de que quiere borrar?',
    confirmDelEspecie: '¿Estás seguro de que quiere borrar LA ESPECIE?',
    confirmApproval: '¿Está seguro de que desea aprobar esta ocurrencia?',
    noResultsFor: 'No hay resultados para'
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Login',
    entrar: 'Iniciar sesión',
    descricao: 'Ingrese sus datos de acceso en el siguiente formulario:'
  },
  exclusaoConta: {
    username: 'Usuário',
    password: 'Contraseña',
    login: 'Eliminar cuenta',
    entrar: 'Enviar',
    descricao: 'Introduce tus datos de acceso en el siguiente formulario para eliminar tu cuenta de la aplicación Invasoras:',
    sucessoExlusao: 'Cuenta eliminada exitosamente.'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impacto sobre la biodiversidad',
    chemical_control: 'control_químico',
    class: 'Clase',
    common_name: 'nombre_común',
    control_biological: 'control_biológico',
    data_bibliography: 'datos_bibliografía',
    economic_impact: 'impacto_económico',
    economic_use: 'uso_económico',
    family: 'familia',
    health_impact: 'impacto_salud',
    intro_data: 'datos_introducción',
    intro_description: 'descripción_introducción',
    invasion_preferential_environments: 'ambientes_preferenciales_invasión',
    kingdom: 'Reino',
    location: 'localidad',
    morphophysiology_description: 'descripción_morfofisiología',
    native_distribution_area: 'áreas_nativa_de_distribución',
    natural_environment: 'ambiente_natural',
    order: 'Órden',
    origin: 'origen',
    phyllum: 'Phyllum',
    physical_control: 'control_físico',
    prevention: 'prevención',
    scientific_name: 'nombre_científico',
    social_impact: 'impacto_social',
    species_id: 'id_especie',
    voc_cause_introduction: 'voc_causa_de_introducción',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_rutas_dispersión',
    voc_dispersion_vectors: 'voc_vectores_dispersión',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introducción',
    voc_reproduction: 'voc_reproducción',
    voc_spread: 'voc_dispersión',
    world_invasive_place: 'sitios_invasión_mundo'
  },
  label: {
    voltar: 'Regresar',
    outro: 'Otro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acceda a la base de datos',
    conhecaNossaRede: 'Red Latino Americana',
    baseDadosNacional: 'Bases de datos nacionales',
    politicaDeDados: 'Política de datos',
    comiteCientifico: 'Comité científico',
    colabore: 'Colaborar',
    especiesMarinhas: 'Especies marinas',
    peixesAquario: 'Peces de acuario',
    peixesAquicultura: 'Peces de acuicultura',
    pescaDesportiva: 'Pesca deportiva',
    pets: 'Animales de compañía',
    plantasForrageiras: 'Plantas forrajeras',
    plantasOrnamentais: 'Plantas ornamentales',
    usoFlorestal: 'Uso forestal',
    tutorial: 'Tutorial',
    especies: 'Especies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referencias',
    projetos: 'Proyectos',
    saibaMais: 'Más info',
    contida: 'Contenida',
    casual: 'Detectada en la naturaleza',
    estabelecida: 'Establecida',
    invasora: 'Invasor',
    exibirListagem: 'Mostrar listado',
    concluir: 'Terminar',
    fechar: 'Cerrar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clic aquí',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Mostrar 10 registros',
    exibir25Reg: 'Mostrar 25 registros',
    exibir50Reg: 'Mostrar 50 registros',
    exibir100Reg: 'Mostrar 100 registros',
    limpar: 'Limpiar',
    buscar: 'Buscar',
    addColaborador: 'Agregar colaborador/a',
    nome: 'Nombre',
    sobrenome: 'Apellido',
    aprovarColaborador: 'Aprobar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidad',
    instituicao: 'Institución',
    endereco: 'Dirección',
    estado: 'Estado',
    cidade: 'Ciudad',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Teléfono',
    telefone2: 'Teléfono 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Red social 1',
    midiaSocial2: 'Red social 2',
    midiaSocial3: 'Red social 3',
    observacoesAdicionais: 'Observaciones adicionales',
    adicionar: 'Agregar',
    editando: 'Editando',
    acoes: 'Acciones',
    usuarixs: 'Usuarios',
    adicionarUsuario: 'Agregar usuarix',
    descricao: 'Descripción',
    ativo: 'Activo',
    sim: 'Sí',
    nao: 'No',
    salvarUsuarix: 'Salvar usuarix',
    senha: 'Contraseña',
    observacoes: 'Observaciones',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomía',
    nomeCientifico: 'Nombre científico',
    adicionarEspecie: 'Agregar especie',
    genero: 'Género',
    familia: 'Familia',
    ordem: 'Orden',
    classe: 'Clase',
    phylumDivisao: 'Phylum / División',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origen',
    descricaoInvalida: 'Descripción no válida',
    selecioneFamilia: 'Por favor, seleccione una familia.',
    selecioneOrdem: 'Por favor, seleccione un Orden.',
    selecioneClasse: 'Por favor, seleccione una Clase.',
    selecioneDivisao: 'Por favor, seleccione una División.',
    selectioneReino: 'Por favor, seleccione un Reino.',
    selectioneGenero: 'Por favor, seleccione un género.',
    addReferencia: 'Agregar referencia',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprobar referencia',
    salvarReferencia: 'Salvar referencia',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Localidad (Ex. Salvador, BA  /  Sudáfrica: Ciudad del Cabo)',
    localFiltro: 'Localidad',
    edicao: 'Edición (ex. 2 ed.)',
    editora: 'Editorial',
    revista: 'Revista',
    anoPublicacao: 'Año de publicación',
    meioDivulgacao: 'Medio de comunicación',
    volume: 'Volumen (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palabra clave',
    anoPublic: 'Año publicación',
    addProjeto: 'Agregar proyecto',
    aprovarProjeto: 'Aprobar Proyecto',
    salvarProjeto: 'Salvar proyecto',
    contato: 'Contacto',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descripción',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    comentarios: 'Comentarios',
    anoInicio: 'Año de inicio',
    anoTermino: 'Año de finalización',
    ocorrencias: 'Ocurrencias',
    exportarDados: 'Exportar datos',
    addOcorrencia: 'Agregar ocurrencia',
    visualizarOcorrencia: 'Ver ocurrencia',
    alterarOcorrencia: 'Modificar ocurrencia',
    ocorrenciasVinculadas: 'Ocurrencias vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Especie',
    aprovarOcorrencia: 'Aprobar ocurrencia',
    salvarOcorrencia: 'Salvar ocurrencia',
    colaborador: 'Colaborador(a)',
    municipio: 'Municipalidad',
    municipioSede: 'Municipalidad (sede)',
    municipioCentroide: 'Municipalidad (centroide)',
    referenciaLocal: 'Referencia local',
    referencia: 'Referencia',
    latitude: 'Latitud',
    longitude: 'Longitud',
    coordenadasCorrespondem: 'Las coordenadas corresponden a',
    distanciaAproximada: 'Distancia aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Cuenca hidrográfica',
    descricaoInvasao: 'Descripción de la invasión',
    abundancia: 'Abundancia',
    situacaoPopulacional: 'Situación poblacional',
    manejo: 'Manejo',
    dataEntrada: 'Fecha de ingreso',
    dataObservacao: 'Fecha de observación (ej. 2020 o 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'Es un ejemplar de museo o de herbario',
    selecioneTipo: 'Seleccione el tipo',
    herbario: 'Herbario',
    museu: 'Museo',
    numeroExemplaInstituicao: 'Número de ejemplar (institución)',
    numeroExemplarColetor: 'Número de ejemplar (colector)',
    selecioneOpcao: 'Seleccione una opción…',
    pointList1: 'A un punto de ocurrencia de uno o más individuos de la especie',
    pointList2: 'Al punto central de un grupo de organismos de la especie',
    pointList3: 'A un punto próximo de la ocurrencia, ubicado a una distancia aproximada de :',
    coordinatesSourceList1: 'A - Una unidad política o administrativa',
    coordinatesSourceList2: 'B - El sitio real de ocurrencia',
    ultimoRegistroBaseDados: 'Últimos registros en la base de datos',
    ultimosAcessos: 'Últimos accesos',
    fichaTecnicaEspecie: 'Ficha técnica de las especies',
    fichaTecnicaEspecieMMA: 'Ficha técnica de las especies MMA',
    fichaTecnicaEspecieMMA2: 'Ficha técnica de las especies MMA versión 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contenida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Datos insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponibles para exportación:',
    biologiaEcologia: 'Biología y ecología',
    usoEconomico: 'Uso económico',
    usoEconomicoDescricao: 'Uso económico - descripción',
    invasaoBiologica: 'Invasión biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nombre científico + sinónimos',
    nomesPopulares: 'Nombres comunes',
    reproducao: 'Reproducción',
    dispersao: 'Dispersión',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes preferenciales de invasión',
    tipoIntroducao: 'Tipo de introducción',
    causaIntroducao: 'Causa de introducción',
    localIntroducao: 'Sitio de introducción',
    anoIntroducao: 'Año de introducción',
    marinho: 'Marino',
    dulcicola: 'Agua dulce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar especie',
    salvarEspecie: 'Salvar especie',
    data: 'Data',
    confiabilidade: 'Confiabilidad',
    resultadosAnalisesRisco: 'Resultados del análisis de riesgo',
    risco: 'Riesgo',
    areaOrigem: 'Área de orígen',
    areaDistribuicaoNatural: 'Área de distribución natural',
    ambientesNaturais: 'Ambientes naturales',
    descricaoEspecie: 'Descripción de la especie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes preferenciales de invasión - descripción',
    outrosLocaisOndeEspecieInvasora: 'Otros sitios donde la especie se comporta como invasora',
    impactosEcologicos: 'Impactos ambientales',
    impactosEconomicos: 'Impactos económicos',
    impactosSociais: 'Impactos sociales',
    impactosSaude: 'Impactos sobre la salud',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nivel de confianza de la evaluación EICAT',
    referenciaEICAT: 'Referencia EICAT',
    dataEICAT: 'Fecha EICAT (ej. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nivel de confianza de la evaluación SEICAT',
    referenciaSEICAT: 'Referencia SEICAT',
    dataSEICAT: 'Fecha EICAT (ej. 2021)',
    digitarSomenteAno: 'Ingresar solamente el año - 2021',
    viasCDBCategorias: 'Vías CDB - categorías',
    viasIntroducao: 'Vías de introducción y dispersión',
    viasVetoresCDBSubcategorias: 'Vías y vectores CDB - subcategorías',
    vetoresIntroducao: 'Vectores de introducción y dispersión',
    introducao: 'Introducción',
    adicionarIntroducao: 'Agregar introducción',
    ano: 'Año',
    descricaoIntroducao: 'Descripción de la introducción',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Eliminar',
    medidasPreventivas: 'Medidas de prevención',
    medidasPreventivasMMA: 'Medidas de prevención MMA',
    medidasDeteccaoPrecoce: 'Medidas de detección temprana',
    controleMecanico: 'Control mecánico',
    controleQuimico: 'Control químico',
    controleBiologico: 'Control biológico',
    distribuicaoPais: 'Distribución en el país',
    vejaMapaOcorrencias: 'Vea el mapa de ocurrencias',
    ecologiaUso: 'Ecología y uso',
    introducaoDispersao: 'Introducción y dispersión',
    analiseRisco: 'Análisis de riesgo',
    nomeComum: 'Nombre común',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinónimos',
    autor: 'Autor',
    subespecie: 'Subespecie',
    autorSubespecie: 'Autor - subespecie',
    pendenteAnalise: 'Pendiente de análisis',
    cancelarVoltar: 'Cancelar / Volver',
    versaoAnterior: 'Versión anterior',
    versaoAtual: 'Versión actual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomía - Lista completa de especies',
    consultaEspeciesOrigemAmbientes: 'Especies, origen, ambientes de invasión e impactos',
    consultaEspeciesManejo: 'Especies y manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica de la especie sin ocurrencias',
    consultaEspeciesOcorrencias: 'Especies y ocurrencias',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocurrencias APP',
    dataCadastro: 'Fecha de registro',
    criadoPor: 'Creado por',
    nivelCerteza: 'Nivel de certidumbre',
    byApp: 'Por aplicación móvil',
    verDados: 'Ver datos',
    appuser: 'APPUser',
    profissao: 'Profesión',
    colobadorJaCadastrado: 'Colaborador ya registrado',
    especialidadeApp: 'Especialidad APP',
    cep: 'Código postal',
    categoriaCDB: 'Categoría CBD',
    subCategoriaCDB: 'Subcategoría CDB',
    mensagemEnviada: 'Mensaje enviado correctamente.',
    argentina: 'Argentina',
    brasil: 'Brasil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    equador: 'Ecuador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    exportar: 'Exportar',
    citacao_bd_new: 'Instituto Hórus 2024. Base de Dados Nacional de Espécies Exóticas Invasoras.',
    citacao_bd_new1: 'Disponible en https://bd.institutohorus.org.br/.',
    citacao_bd_new2: 'Acceso en XX/XX/XXXX.',
    adicionarAreaProtegida: 'Agregar área protegida',
    configuracoes: 'Ajustes',
    grupoDeEmails: 'Grupo de correo electrónico',
    adicionarGrupoEmails: 'Agregar grupo de correo electrónico',
    emails: 'E-Mails',
    adicionarEmail: 'Agregar e-mail',
    grupoAtivo: 'Grupo ativo',
    uf: 'UF'
  },
  home: {
    destaque1: 'Base de Datos Nacional sobre Especies Exóticas Invasoras',
    destaqueDescricao1: 'Bienvenidx! Esta base de datos cuenta con la colaboración de muchas personas comprometidas con la conservación ambiental. Te invitamos a recorrer nuestras páginas!',
    destaque2: 'Desde 2005 documentando especies exóticas invasoras y compartiendo la información online!',
    destaqueDescricao2: 'Este sistema de bases de datos fue desarrollado y se mantiene actualizado con recursos nacionales e internacionales y cuenta con un Comité Científico de Apoyo.',
    destaque3: 'Como funciona?',
    destaqueDescricao3: 'Este es una base de datos de acceso abierto. Utilice la información disponible, pero por favor cite este site como referencia en su trabajo. Los proveedores regulares de datos pueden solicitar una cuenta para contribuir con datos.',
    destaque4: 'Nuestro propósito',
    destaqueDescricao4: 'Proveer datos sobre especies exóticas invasoras en Brasil para cooperar con la gestión pública, apoyar programas de manejo, auxiliar en la investigación científica e informar al público en general.',
    titulo: 'Busque especies, filtre y exporte datos',
    conteudo: 'Listas de especies, características ecológicas, rango nativo de distribución, lugares donde la especie invade a nivel global, vectores y rutas de dispersión, análisis de riesgo, usos, opciones de manejo, localidades de ocurrencia en Brasil, referencias.',
    conteudoEspecies: 'Listado de especies, características ecológicas, área de origen, dónde es invasora en el mundo, vías y vectores de introducción, análisis de riesgo, usos, opciones de manejo, ocurrencia en Brasil, referencias',
    conteudoColaboradores: 'Registro de proveedores de datos. Si quiere entrar en contacto con quien proveyó la información sobre alguna especie o un sitio de ocurrencia en particular busque aquí.',
    conteudoReferencias: 'Lista de las referencias relacionadas con las especies incluidas en la base de datos.',
    conteudoProjetos: 'Proyectos de investigación y de manejo de especies exóticas invasoras en la Brasil.',
    titulo1: 'Conozca las especies exóticas invasoras.',
    conteudo1: 'Listas de especies exóticas invasoras organizadas según el motivo o vía de introducción a Brasil, usos o efectos sobre los ecosistemas. Las páginas incluyen una explicación sobre cada grupo de especies y vínculos para fuentes de información adicionales.'
  },
  areaParceiros: {
    titulo: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latino Americana',
    paragrafo1: 'El origen de esta red de bases de datos se remonta al año 2004, como parte de la Red Temática sobre Especies Exóticas Invasoras de la Red Interamericana de Información sobre Biodiversidad (I3N-IABIN), establecida como una iniciativa de la Cumbre de las Américas.',
    paragrafo2: 'Cada país integrante de I3N designó un líder nacional cuya función era desarrollar una base de datos nacional sobre estándares comunes y compartir información sobre especies exóticas invasoras:',
    paragrafo2_1: '',
    marcador1: 'Dr. Sergio M. Zalba, Profesor de la Universidad Nacional del Sur e investigador del CONICET (desde 2004);',
    marcador2: 'Dra. Sílvia R. Ziller, Fundadora y Directora Ejecutiva del Instituto Horus de Desarrollo y Conservación Ambiental (desde 2004);',
    marcador3: 'Dr. Aníbal Pauchard, Profesor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'PhD. Eduardo Chacón-Madrigal, Profesor de la Universidad de Costa Rica. Investigador en el Centro de Investigación en Biodiversidad y Ecología Tropical. Co-coordinador de Flora Invasive and Naturalized of Central America;',
    marcador5: 'Dr. Diego Inclán, Director Ejecutivo del Instituto Nacional de Biodiversidad (desde 2021) y Francisco José Prieto Albuja;',
    marcador5_1: 'Dra Lilian Ferrufino, Profesora de la Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Dr. Ernesto Brugnoli, Profesor de la Universidad de la República, Montevideo (desde 2005), y el MSc. Marcelo Iturburu, Coordinador del Comité Nacional de Especies Exóticas Invasoras del Ministerio de Ambiente (desde 2018).',
    marcador6: 'el Programador João Scucato, de Curitiba – PR, Brasil, que desarrolló la primera versión en Microsoft Access, 2004-2005;',
    marcador7: 'el Programador Alejandro Moreno, de Argentina, ha proveído soporte a las bases de datos muchas veces desde el 2006. Mantuvo copias de los archivos y rescató datos de Paraguay y Uruguay para esta nueva edición, además de desarrollar la',
    marcador7_1: 'Plataforma Común',
    marcador7_2: 'de los países de la red.',
    marcador8: 'el Diseñador Rafael Moura y por el Programador Thiago Lôbo, de Brasil, a través de la',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'en Florianópolis – SC, Brasil;',
    marcador9: 'el programador Leonardo Rotondano, responsable del desarrollo de la aplicación para teléfono celular utilizado para el aporte de ocurrencias de especies exóticas invasoras.',
    paragrafo6: 'La red espera ampliar su cobertura territorial incorporando otros países de la región de manera de ampliar la disponibilidad de información sobre especies exóticas invasoras para el conocimiento público, la gestión gubernamental y el manejo.',
    paragrafo7: 'Apoyo',
    paragrafo8: 'Periodo 2021',
    paragrafo9: 'Periodo 2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de Datos Nacional',
    titulo: 'La Base de Datos Nacional de Especies Exóticas Invasoras',
    paragrafo1: 'Esta plataforma de información fue desarrollada en el año 2004 mediante una asociación entre el Instituto Horus y la Universidad Nacional del Sur en Bahía Blanca, Argentina (Dr. Sergio Zalba), como parte de la red de información sobre especies exóticas invasoras, entonces llamada red I3N (IABIN Invasives Information Network). La red estaba coordinada, en aquel momento, por Andrea Grosse, una bióloga muy dedicada a la red que hizo posible el desarrollo de la estructura de la base de datos y de varios otros productos, como análisis de riesgos, manuales y cursos de formación sobre el tema. Otra persona que tuvo un papel decisivo en el crecimiento de la red fue la Dra. Annie Simpson, del USGS, entonces responsable de la Red Mundial de Información sobre Especies Invasoras (GISIN), cuyo objetivo era establecer normas de información sobre las especies exóticas invasoras para facilitar la comunicación entre las bases de datos mundiales y de los distintos países. Los recursos para el desarrollo de la base de datos fueron proporcionados por el Banco Mundial (GEF) para un proyecto coordinado por el Servicio Geológico de los Estados Unidos (USGS) que tenía como objetivo establecer la IABIN - Red Interamericana de Información sobre Biodiversidad.',
    paragrafo2: 'La Red I3N existió entre los años 2001 y 2011, cuando finalizó el proyecto. Durante este periodo, la estructura de la base de datos se difundió a 21 países de América por medio de cursos de formación para la gestión del tema a nivel nacional y de la propia base de datos: Argentina, Brasil, Uruguay, Chile, Paraguay, Bolivia, Perú, Ecuador, Colombia, Venezuela, Surinam, República Dominicana, Bahamas, Jamaica, Santa Lucía, Panamá, Costa Rica, Honduras, El Salvador, Nicaragua y Guatemala.',
    paragrafo3: 'Lamentablemente, la mayoría de las bases de datos nacionales se han perdido con el tiempo. Las bases de datos que se han mantenido desde el principio son la de Argentina (Universidad Nacional del Sur, Administrador Dr. Sergio Zalba); la de Brasil (Instituto Horus, Dra. Silvia Ziller y Dra. Michele de Sá Dechoum), la de Uruguay (Universidad de la República en Montevideo, Dr. Ernesto Brugnoli y Comité de Especies Exóticas Invasoras del Ministério de Medio Ambiente, M.Sc. Marcelo Iturburu) y la de Jamaica (Institute of Jamaica, Dra. Suzanne Davis). La cooperación entre estas bases de datos nunca ha sido interrumpida por sus administradorxs.',
    paragrafo4: 'En 2011 se actualizó la base de datos desde el formato original de Microsoft Access al formato de software libre MySQL. En aquella oportunidad se introdujeron numerosas correcciones y mejoras basadas en los informes de experiencia de los usuarios de varios países. La nueva versión se distribuyó a los países que formaban parte de la red.',
    paragrafo5: 'Diez años después, en 2021, la Iniciativa BioBridge de la Secretaría del Convenio Internacional sobre la Diversidad Biológica en Montreal, Canadá, aportó recursos para una nueva actualización para Argentina, Brasil, Uruguay y Paraguay. Una vez más, se han incorporado varias mejoras para facilitar a los usuarios la introducción, edición y exportación de datos. Esperamos que esta versión sea más accesible y fácil de usar, y que la base de datos nacional sirva de base para la gestión pública, el manejo en campo y que permita la elaboración de muchos artículos técnicos y científicos. También esperamos que el conocimiento sobre especies exóticas invasoras estimule el uso de especies nativas, especialmente para proteger la diversidad biológica y la resiliencia de los ecosistemas naturales en Brasil y en América.',
    paragrafo6: 'Agradecemos a las personas que han colaborado con la Base de Datos Nacional, tanto por haber aportado datos como por haber ayudado a validar las especies y las ocurrencias, y por todo el apoyo que se ha necesitado. La riqueza de los datos disponibles es, sin duda, el resultado de un importante esfuerzo conjunto. Agradecemos especialmente a la Dra. Michele de Sá Dechoum, Docente de la Universidad Federal de Santa Catarina y Coordinadora del Laboratorio de Ecología de Invasiones Biológicas, Manejo y Conservación ',
    paragrafo7: 'por la cooperación en la gestión de la Base de Datos Nacional y la participación de los estudiantes en la actualización de información a través de un proyecto de extensión universitaria.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de datos',
    titulo: 'Conceptos y referencias',
    criterio: 'Criterios para la inclusión de especies',
    avaliacao: 'Evaluación y revisión de datos',
    validacao: 'Validación de datos',
    exclusao: 'Eliminación de cuenta',
    paragrafo1: 'A los efectos de este sistema de datos se adoptan las siguientes definiciones, de acuerdo con el Convenio de Diversidad Biológica:',
    marcador1: 'especie nativa: aquella que se encuentra dentro del área de distribución geográfica donde evolucionó y forma parte de una comunidad biológica en equilibrio;',
    marcador2: 'especie exótica: aquella que se encuentra fuera de su área de distribución natural pasada o presente;',
    marcador3: 'especie exótica invasora: toda especie exótica que afecta o amenaza hábitats, ecosistemas o especies causando alteraciones en los ambientes naturales.',
    paragrafo2: 'La Base de Datos Nacional de Especies Exóticas Invasoras de Brasil tiene enfoque en las especies exóticas invasoras que ya están presentes en el país. Las especies registradas están siempre relacionadas a por lo menos una ocurrencia geográfica en Brasil.',
    paragrafo3: '',
    paragrafo4: 'El sistema de clasificación de ambientes terrestres utilizado es el Manual de Clasificación de la Vegetación Brasilera (IBGE, edición de 2012). Los términos utilizados para ambientes marinos y de agua dulce fueron definidos con especialistas en esas áreas. El vocabulario controlado utilizado en los campos de ambientes preferenciales de invasión, rutas y vectores de dispersión, formas biológicas, impactos y métodos de control fueron definidos de acuerdo con los términos utilizados a por la Base de Datos Global sobre Especies Invasoras del Grupo Especialista en Especies Invasoras de la UICN',
    paragrafo5_1: 'Las referencias taxonómicas principales se toman de los sistemas propuestos por el Jardín Botánico de Missouri ',
    paragrafo5_2: 'The Plant List ',
    paragrafo5_3: 'y por el Sistema Integrado de Información Taxonómica ',
    paragrafo5_4: 'Referencias complementarias incluyen el Registro Mundial de Especies Marinas',
    paragrafo5_5: 'la Base de Datos Global sobre Especies Exóticas Invasoras',
    paragrafo5_6: 'y el Compendio de Especies Invasoras de CABI',
    paragrafo6: 'Para cada punto de ocurrencia las especies se califican como:',
    marcador4: '– cuando su presencia se limita o está restringida a sistemas de cultivo o cría o vive dependiendo de manera directa de las personas, por ejemplo como animal de laboratorio, de compañía o de acuario, planta de jardín, sin evidencias de escape de individuos en ese sitio.',
    marcador5: '– cuando la especie fue vista en ambientes naturales o seminaturales, fuera de sistemas de cultivo o cría, todavía sin evidencias de haber establecido un núcleo poblacional.',
    marcador6: '– cuando la especie se reproduce de manera efectiva, conformando una población autosostenible pero se mantiene en un área limitada, próxima al sitio de introducción local.',
    marcador7: '– cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local.',
    paragrafo7: 'Es importante notar que el estado de invasión es un atributo de la localidad de ocurrencia, no de las especies. De este modo, la misma especie puede comportarse como invasora en una localidad, estar contenida en otro sitio y haber sido detectada en la naturaleza en un tercero.',
    paragrafo8: 'Cada información de ocurrencia recibida queda siempre vinculada a la fuente, o sea, a la persona que la proveyó y/o a un trabajo científico, incluyendo unx o más autorxs. Otros datos son referenciados en los campos descriptivos a la medida de lo posible. En el caso de dudas, cuestionamientos o necesidad de más información sobre algún dato, la fuente puede ser indicada o consultada. El vínculo entre las informaciones y los proveedores de datos es, más que todo, reconocer la autoría y valorizar el trabajo y el apoyo de lxs colaboradorxs.',
    paragrafo8_en: '',
    paragrafo9_1: 'En el caso que tengas datos sobre especies exóticas invasoras para contribuir con la base de datos, por favor utilice la hoja Excel disponible en nuestro sitio web y envíela para ',
    paragrafo9_2: ' o haga contacto con nosostrxs.',
    paragrafo10: 'En caso de dudas o cuestionamientos, por favor envíe un correo para ',
    paragrafo11: 'Los criterios básicos para la inclusión de especies en la base de datos son: (a) que estén presentes en Brasil y exista por lo menos uno o algunos registros de ocurrencia confiables; (b) tengan histórico de invasión registrado en el propio país o en otra parte del mundo, generalmente bajo condiciones climáticas que facilitan la adaptación de la especie a algún tipo a climático de Brasil. Puede haber excepciones para especies exóticas invasoras que aún no expresan comportamiento invasor, pero sean consideradas de alto riesgo para la diversidad biológica brasilera y ocurran en el país de forma contenida (en cautiverio, acuarios, laboratorios, etc.) o en países limítrofes o con los cuales Brasil mantiene relaciones comerciales importantes, con riesgo inminente de introducción. Ninguna especie considerada invasora en el país es incluida sin que se registre por lo menos un registro validado de ocurrencia.',
    paragrafo12: 'La base de datos contempla especies exóticas invasoras de todos los grupos biológicos con potencial de impacto sobre la diversidad biológica y/o sobre ambientes naturales o seminaturales. Las mismas especies pueden generar impactos sobre la economía, valores sociales o culturales o sobre la salud humana o animal, pero el foco de este sistema es la conservación de la diversidad biológica y, por ello, no se incluyen en la base de datos especies exóticas que se comporten como malezas, plagas o patógenos de las personas o de los animales domésticos pero que no hayan sido detectadas todavía en la naturaleza o afectando a especies de la flora y fauna nativa y no tengan antecedentes internacionales en ese sentido.',
    paragrafo13: 'Cada vez que una especie es registrada en la base de datos, los datos complementarios son incluidos. A la medida que nuevos datos quedan disponibles, son incluidas nuevas informaciones y, principalmente, nuevas ocurrencias de la especie en el país. Esos datos son obtenidos de publicaciones técnicas y científicas, de colaboradores directos que trabajan en el terreno, de administradores y funcionarios de áreas protegidas o por observación y coleta de puntos directamente en el terreno. La inclusión de coordinadas geográficas es obligatoria para todos los registros.',
    paragrafo14: 'Los datos son revisados continuamente a la medida que más informaciones sobre una especie son publicadas o comunicadas directamente por expertos. Puede entonces haber cuestionamiento sobre especies inseridas en la base de datos por cuenta de la distribución natural o de la capacidad de invasión, casos en que se busca apoyo de expertos en el grupo biológico específico para decidir si la especie debe ser removida. Algunas especies incluidas durante los primeros años de la base de datos fueron posteriormente removidas porque no había datos suficientes para atender a los criterios de inclusión o porque faltaban evidencias de invasión biológica. Eso puede ocurrir, por ejemplo, cuando se reconoce que una especie exótica es observada en un sitio por mucho tiempo, pero no existen evidencias de invasión a lo largo del tiempo, ni  hay datos que confirmen el potencial de invasión en Brasil o en otras partes del mundo. En los casos de remoción de especies de la base de datos, los datos son almacenados  separadamente y quedan en un listado de espera para el caso que nuevas revisiones indiquen que debe volver a ser incluida.',
    paragrafo15: 'La confiabilidad de los datos es una de las principales preocupaciones de los administradores de este sistema. La validación de la información se evalúa en dos niveles principales: respecto de la correcta identificación de cada taxón y en referencia con la exactitud del sitio al que se refiere cada dato. Para eso se utilizan estándares y criterios consensuados con el resto de los sistemas que conforman la red de información sobre EEI.',
    paragrafo16: 'Criterios para validar la identidad taxonómica de cada taxón',
    marcador8: 'El proveedor del dato (autor de la publicación o colector del espécimen) puede considerarse un especialista del grupo en cuestión o tiene una experiencia equivalente?',
    marcador9: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del organismo en cuestión?',
    marcador10: 'La ocurrencia está documentada en una publicación indexada y con evaluación de pares o en un documento técnico realizado o revisado por especialistas?',
    marcador11: 'La información se corresponde con un ejemplar de herbario o con un espécimen depositado en una colección de museo?',
    marcador12: 'La ocurrencia corresponde a una especie que ya estaba registrada para el país?',
    marcador13: 'El grupo al que pertenece la especie tiene una taxonomía estable y bien conocida?',
    marcador14: 'Las características de la especie permiten una determinación relativamente simple?',
    paragrafo17: 'Una respuesta positiva a al menos una de las preguntas 1, 2, 3 o 4 lleva directamente a la recomendación de considerar al dato como validado. En caso de responder negativamente a estas cuatro preguntas, la validación se obtendrá solamente con respuestas positivas a las tres últimas (5, 6 y 7).',
    paragrafo18: 'Criterios para validar la ubicación asociada a del sitio donde decimos que se detectó una especie',
    marcador15: 'El dato incluye coordenadas geográficas? Se consideran tanto coordenadas tomadas directamente en el sitio de la observación como otras que correspondan a alguna referencia geográfica cercana (en el caso que esto se indique).',
    marcador16: 'Se incluye una descripción del sitio que permite su identificación confiable?',
    marcador17: 'La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del sitio?',
    marcador18: 'Las coordenadas geográficas provistas coinciden con la ubicación del sitio descripto y/o fotografiado?',
    marcador19: 'La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión?',
    marcador20: 'Las condiciones ecológicas del sitio, inferidas en función de la localización indicada, resultan compatibles con los requerimientos generales de nicho de la especie?',
    paragrafo19: 'El dato se considera validado, desde el punto de vista de su ubicación geográfica, si:',
    marcador21: 'se responde de manera afirmativa a las preguntas 1, 2 y/o 3, y 4 (el dato incluye coordenadas geográficas, se brinda una referencia inequívoca del sitio y/o se provee una fotografía que permite la identificación confiable de lugar y las coordenadas provistas coinciden con la ubicación del sitio descripto o fotografiado, o,',
    marcador22: 'se asigna una respuesta positiva o negativa a la pregunta 1 (se brindan o no coordenadas geográficas), una respuesta negativa a las preguntas 2 y 3 (no se describe en detalle el sitio ni se adjunta una fotografía que permita ubicarlo), pero se responde de manera positiva a las preguntas 5 y 6 (la especie ya fue citada para el país y la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, y las condiciones ecológicas del sitio resultan compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo20: 'La localización se considerará no validada si: ',
    marcador23: 'a- se responde de manera positiva a la pregunta 1 y a las preguntas 2 y/o 3, pero negativa a la 4 (las coordenadas no coinciden con el sitio descripto o fotografiado), o.  ',
    marcador24: 'b- se responde de manera negativa a las preguntas 2 y 3, y negativa a al menos una de las preguntas 5 y 6 (el dato no cumple con una o con ninguna de estas dos condiciones: 1- La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, 2- el sitio reúne condiciones ecológicas compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo21: 'Finalmente, el dato se considera validado en caso de pasar el análisis de validación de los dos criterios (taxonomía y ubicación). ',
    paragrafo22: 'Con la eliminación definitiva de su cuenta, todos sus datos serán eliminados y será necesario crear una nueva cuenta si desea volver a registrarse en la aplicación.',
    paragrafo23: "Al elegir la opción de 'Eliminar cuenta', todos los datos de su cuenta y perfil serán eliminados y no será posible recuperar su cuenta.",
    paragrafo24: 'La cuenta puede tardar algunas horas en ser eliminada por completo.',
    marcador25: 'Acceda a su cuenta y abra la pestaña de configuraciones;',
    marcador26: "Haga clic en el botón 'Eliminar cuenta';",
    marcador27: "Aparecerá un aviso preguntando si realmente desea eliminar la cuenta, haga clic en 'Sí';",
    paragrafo25: 'Listo, se ha solicitado la eliminación de su cuenta.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comité científico',
    paragrafo1: 'La Base de Datos Nacional de Especies Exóticas Invasoras cuenta con el apoyo de un grupo de expertos en diversos campos del conocimiento para realizar la validación de los datos recibidos. Este grupo incluye a taxónomos o especialistas en grupos específicos de especies, así como a profesionales con experiencia en el campo cuyos conocimientos son muy relevantes para apoyar la verificación de la información.',
    paragrafo2: 'A partir de 2021 esta contribución se ha formalizado, con algunas personas invitadas a componer un Comité Científico para apoyar la validación de datos y la gestión de la Base de Datos Nacional de Especies Exóticas Invasoras. El papel del Comité Científico es proporcionar apoyo para la validación de datos, aportar datos de interés a la base de datos y ayudar a construir y mantener la red de colaboradores para permitir la actualización de la información y aumentar el número de contribuciones de datos.',
    paragrafo3: 'El Comité Científico se nombra por periodos de tres años, y los nombramientos pueden ser renovados o los miembros sustituidos. El primer Comité Científico se formó en el curso de 2021 por:',
    marcador1: 'Sílvia R. Ziller, Ing. Forestal, M.Sc., Dr., Fundadora del Instituto Hórus, Administradora de la Base de Datos;',
    marcador2: 'Michele de Sá Dechoum, Bióloga, M.Sc., Dr., Docente de la Universidad Federal de Santa Catarina y colaboradora del Instituto Hórus desde 2007 y Administradora de la Base de Datos;',
    marcador3: 'Rafael Dudeque Zenni, Ing. Forestal, Dr., Docente de la Universidad Federal de Lavras – MG, colaborador de la base de datos desde 2004;',
    marcador4: 'Sergio Zalba, Biólogo, M.Sc., Dr. Profesor de la Universidad Nacional del Sur en Bahía Blanca, Argentina, y Administrador de la Base de Dados Nacional de Espécies Exóticas Invasoras de Argentina;',
    marcador4b: 'Rosana M. Rocha, Bióloga, M.Sc., Dra., Profesora del Departamento de Zoología de la Universidad Federal de Paraná;',
    marcador4c: 'Mário Luís Orsi, Biólogo, Dr., Profesor del Departamiento de Biología Animal y Vegetal, LEPIB, Universidad Estadual de Londrina, Paraná.',
    paragrafo4: 'Atribuciones del Comité Científico',
    marcador5: 'decidir la inclusión de nuevas especies en la base de datos;',
    marcador6: 'decidir la eliminación de especies por falta o inconsistencia de los datos;',
    marcador7: 'apoyo para la validación de la información en la base de datos;',
    marcador8: 'apoyo para la revisión y búsqueda de datos; ',
    marcador9: 'apoyo para la búsqueda y consulta a colaboradores y expertos para la contribución y validación de datos;',
    marcador10: 'recomendaciones para mejorar la base de datos;',
    marcador11: 'mejora continua del nivel de información.'
  },
  especiesInstitucional: {
    titulo: 'Especies',
    paragrafo1: 'Esta es la parte de la base de datos que contiene información acerca de las especies exóticas invasoras. Esos datos se están recopilando desde el año 2004, cuando se realizó el primer relevamiento nacional por parte del Ministerio de Medio Ambiente. La información es enviada por colaboradores diversos, incluyendo especialistas en distintos grupos biológicos que también ayudan a validar datos, y compiladas a partir de artículos científicos y publicaciones técnicas.',
    paragrafo2_1: 'Si tuviera datos de ocurrencias de esas especies o sobre especies exóticas invasoras aún no incluidas, ',
    paragrafo2_2: 'contribuya para actualizar esta base de dados',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Para citar datos del sistema, utilice el siguiente formato:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC, Brasil.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar la base de datos',
    tutorialDescricao1: 'Este es el formato de referencia para la citación de la base de datos en publicaciones.',
    tutorialTitulo2: 'Use los filtros',
    tutorialDescricao2: 'Aquí tenemos algunas opciones para filtrar contenido.',
    tutorialTitulo3: 'Use los filtros: seleccione datos',
    tutorialDescricao3: 'Elija filtros y haga combinaciones para seleccionar datos por lugar, forma biológica, uso, grupo taxonómico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este es el número de especies exóticas invasoras en la base de datos resultante de las búsquedas con filtros.',
    tutorialDescricao5: 'Aquí puede elegir cuántos registros ver por página.',
    tutorialTitulo6: 'Lista de especies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas las especies o resultados del uso de filtros. Haga clic sobre el nombre de la especie para ver los datos completos.',
    tutorialTitulo6_1: 'Exportar datos',
    tutorialDescricao6_1: 'Aquí puede filtrar datos y elegir el formato para exportar.',
    tutorialTitulo7: 'Taxonomía',
    tutorialDescricao7: 'Clasificación taxonómica de la especie seleccionada, nombres comunes, sinónimos, y una foto cuando fuera posible!',
    tutorialTitulo8: 'Ecología y uso',
    tutorialDescricao8: 'Descripción, biología y ecología de la especie seleccionada, de dónde viene, que ambientes invade y usos económicos.',
    tutorialTitulo9: 'Introducción y dispersión',
    tutorialDescricao9: 'Cómo llegó la especie al sitio donde es invasora, por qué fue introducida y cómo se dispersa a otros sitios.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientales, económicos, a la salud y culturales. Clasificación de impactos ambientales por el protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detección temprana, control mecánico, químico y biológico para la especie seleccionada.',
    tutorialTitulo12: 'Análisis de Riesgo',
    tutorialDescricao12: 'Resultados de análisis de riesgo para invasión biológica realizadas para la especie seleccionada.',
    tutorialTitulo13: 'Ocurrencias',
    tutorialDescricao13: 'Sitios, ambientes, áreas protegidas, estados donde la especie ocurre en el país y estadio de invasión – vea el mapa!',
    tutorialDescricao14: 'Lista de referencias usadas para compilar datos sobre la especie seleccionada y otras publicaciones sobre la especie.',
    tutorialTitulo15: 'Proyectos',
    tutorialDescricao15: 'Lista de proyectos de investigación y manejo que incluyen la especie seleccionada.'

  },
  colaboradoresInstitucional: {
    titulo: 'Colaboradorxs',
    paragrafo1: 'Son colaboradorxs de la Base de Dados Nacional las personas que envían información sobre especies exóticas invasoras, ya sea en forma de publicaciones técnicas o científicas, como dados asociados a especímenes de herbario o museo, o de observaciones de campo, y que ayudan a identificar especies. ',
    paragrafo2: 'La contribución de información sobre las ocurrencias de especies ya listadas es extremadamente relevante para mantener registros de distribución actualizados y detectar nuevos sitios de invasión, contribuyendo con iniciativas de gestión o manejo, así como para la divulgación del conocimiento científico. ',
    paragrafo3: 'Para citar datos del sistema, utilice el siguiente formato:',
    paragrafo4_1: 'Base de Dados Nacional de Especies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  especiesMarinhasInstitucional: {
    titulo: 'Especies marinas',
    paragrafo1: 'La mayoría de las especies marinas exóticas invasoras se introducen de forma no intencional a partir de la navegación comercial y el movimiento de estructuras como plataformas de petróleo y gas. Algunas especies son transportadas como larvas en el agua de lastre de los barcos, otras se adhieren a los cascos de los barcos y a otras estructuras, un proceso llamado bioincrustación. La mayoría de las especies marinas invasoras son invertebrados (camarones, cangrejos, cirripedios, moluscos, poliquetos, etc.), algunas son peces, otras son algas.',
    paragrafo2: 'Como no se conoce bien la zona de origen de muchas especies, a veces es difícil clasificarlas como invasoras. Así, a veces se hacen inferencias sobre los procesos de invasión biológica a partir del conocimiento de que la especie no existía en un lugar o región determinados.',
    paragrafo3: 'Más información.',
    paragrafo4: 'Tubastrea coccinea - coral-sol',
    paragrafo4b: 'Foto: Bruna Folchini Gregoletto',
    paragrafo5: 'Pterois volitans - pez león',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Ophiotela mirabilis - ofiuro',
    paragrafo7: 'Charybdis helleri - siri-bidu'
  },
  peixesAquarioInstitucional: {
    titulo: 'Peixes de acuario / acuariofilia',
    paragrafo1: 'Los peces de acuario son las "mascotas" más numerosas del mundo en la actualidad. Brasil es un gran proveedor de peces ornamentales debido a la riqueza de la cuenca del Amazonas y el Pantanal. Sin embargo, la mayoría de los peces comercializados son exóticos en los lugares de venta. Estos animales nunca deben ser liberados en lagos, ríos o el mar porque pueden depredar especies autóctonas, alterar el ambiente acuático o transmitir enfermedades y parásitos.',
    paragrafo2: 'Además, pueden convertirse en invasores, como es el caso del pez león, que escapó de un acuario de Florida (EE.UU.) y ahora está en todo el Mar Caribe y corre el peligro de llegar a la costa brasileña.',
    paragrafo3: 'Es un gran riesgo para los arrecifes de coral, como en Abrolhos y Fernando de Noronha. ¡Nunca sueltes mascotas en la naturaleza!',
    paragrafo4: 'Más info',
    marcador1: 'Los peces ornamentales exóticos amenazan la biodiversidad',
    marcador2: 'Invasores exóticos',
    marcador3: 'Ciência Hoje',
    paragrafo5: 'Laetacara araguaiae - carazinho',
    paragrafo5b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo6: 'Pterygoplichthys ambrosettii (juvenil)',
    paragrafo6b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo7: 'Laetacara araguaiae carazinho',
    paragrafo7b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo8: 'Hyphessobrycon eques - mato-grosso',
    paragrafo8b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo9: 'Astronotus crassipinnis - oscar',
    paragrafo9b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo10: 'Danio rerio - paulistinha',
    paragrafo10b: 'Foto: Diego Azevedo Zoccal Garcia',
    paragrafo11: 'Pterois volitans - pez león',
    paragrafo11b: 'Foto: Instituto Horus'
  },
  peixesAquiculturaInstitucional: {
    titulo: 'Peces de acuicultura',
    paragrafo1: 'La acuicultura es la principal causa de introducción de peces de agua dulce en Brasil. Muchas especies son muy agresivas y tienen antecedentes de haber extinguido especies de peces nativas en varios países del mundo, como la tilapia-del-Nilo y el bagre africano. El panga es un pez asiático que se está empezando a criar en Brasil sin la autorización del IBAMA, con un alto potencial de invasión e impacto en la fauna nativa. Intenta informarte antes de decidir qué pescado vas a consumir, para no contribuir a los impactos que puedan causar.',
    paragrafo2: 'El problema es que las estructuras de cría -presas y tanques- no son seguras y los peces se escapan al medio natural, o incluso se liberan intencionadamente durante la manipulación de los tanques. Pueden depredar especies autóctonas, alterar el ambiente acuático o transmitir enfermedades y parásitos.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo3b: 'http://www.fao.org/fishery/code/en',
    paragrafo4: 'Cichla kelberi - tucunaré',
    paragrafo5: 'Coptodon rendalli - tilapia',
    paragrafo6: 'Cyprinus carpio - carpa comun',
    paragrafo7: 'Micropterus salmoides - black bass',
    paragrafo7b: 'Foto: LEPIB - UEL PR',
    paragrafo8: 'Oreochromis niloticus - tilapia nilótica'
  },
  pescaDesportivaInstitucional: {
    titulo: 'Peces para pesca deportiva',
    paragrafo1: 'La pesca deportiva es una actividad de recreación que a menudo implica la introducción de especies exóticas, generalmente depredadores voraces. Aunque la introducción de especies y la transposición de peces a cuencas hidrográficas donde no se encuentren está prohibida sin autorización del IBAMA (Portaria IBAMA 145-N/1998), muchos peces han sido sacados de sus cuencas hidrográficas originales y llevados a otras donde no son nativos. Este proceso genera impactos en los peces nativos por depredación, competencia y transmisión de parásitos y enfermedades, además de graves desequilibrios ambientales.',
    paragrafo2: 'La pesca deportiva lleva asociada la introducción de especies exóticas utilizadas como carnada viva, que pueden escapar del anzuelo o incluso ser arrojadas a los ríos al final de la actividad de recreación. Los peces y otras especies que se utilicen como carnada viva sólo deben emplearse si son autóctonos del río donde se desarrolla la actividad pesquera para evitar la introducción de especies exóticas y los impactos sobre las especies autóctonas.',
    paragrafo3: '¡Nunca sueltes animales exóticos en la naturaleza!',
    paragrafo4: 'Micropterus salmoides - black bass',
    paragrafo4b: 'Foto: LEPIB - UEL PR',
    paragrafo5: 'Cichla kelberi - tucunaré',
    paragrafo5b: 'Foto: LEPIB - UEL PR',
    paragrafo6: 'Cichla kelberi - tucunaré',
    paragrafo6b: 'Foto: LEPIB - UEL PR',
    paragrafo7: 'Más información en esta publicación'
  },
  petsInstitucional: {
    titulo: 'Mascotas',
    paragrafo1_1: 'El término ',
    pet: 'mascota',
    paragrafo1_2: ' se refiere a los animales domésticos, desde los peces de acuario hasta los perros y gatos. Algunos animales incluidos en la lista, especialmente los perros y los gatos, sólo se consideran problemáticos cuando se encuentran dentro de áreas naturales donde depredan la fauna autóctona y causan graves impactos en la conservación de la biodiversidad.',
    paragrafo2: '¡No deje a su mascota suelta en zonas naturales!',
    paragrafo3: 'Trachemys scripta – tortuga de orejas rojas ',
    paragrafo3b: 'Foto: Marcos Tortato',
    paragrafo4: 'Felis catus - gato doméstico',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Canis familiaris - perro doméstico',
    paragrafo5b: 'Foto: Marcos Tortato',
    paragrafo6: 'Trachemys dorbignyi - tigre-d´água',
    paragrafo6b: 'Foto: Marcos Tortato'
  },
  plantasForrageirasInstitucional: {
    titulo: 'Plantas forrajeras',
    paragrafo1: 'Muchas plantas se introdujeron para su uso como forraje, es decir, para alimentar al ganado como bueyes, vacas, cabras, caballos y burros. Se trata principalmente de gramíneas, entre ellas la brachiaria, que es una invasora muy agresiva en áreas naturales y degradadas, incluso en humedales y pequeños arroyos. Pero también hay arbustos como la leucena y árboles como la algarroba, cuyas vainas se utilizan como alimento para las cabras.',
    paragrafo2: 'Estas especies se han extendido ampliamente en el mundo tropical y causan importantes impactos en el ambiente al modificar los ciclos naturales del fuego, reducir el volumen de agua y desplazar a las especies vegetales nativas, formando agrupaciones densas y dominantes.',
    paragrafo3: 'Más información en esta publicación',
    paragrafo4: 'Urochloa decumbens - brachiaria',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Prosopis juliflora - algarrobo',
    paragrafo5b: 'Foto: Leonaldo Andrade',
    paragrafo6: 'Melinis minutiflora - pasto miel',
    paragrafo6b: 'Foto: Carlos Romero Martins',
    paragrafo7: 'Hyparrhenia rufa - pasto jaragua',
    paragrafo7b: 'Foto: Instituto Horus'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'Las plantas ornamentales representan alrededor del 50% de todas las plantas exóticas invasoras presentes en Brasil. La elección de las plantas para jardinería, sombra y arborización urbana puede contribuir a la conservación de la naturaleza, lo que abarca el suministro de agua, la estabilidad climática y muchos otros "servicios" que la naturaleza presta al ser humano.',
    paragrafo2_1: '¡Cultive plantas nativas de su región!',
    paragrafo2_2: 'Conozca, evite y reemplace las plantas exóticas invasoras de su jardín.',
    paragrafo3: 'Consulte plantas alternativas para su región en la página de ',
    arquiflora: 'Arquiflora',
    paragrafo4: 'Tecoma stans - ipê-de-jardim',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Furcraea foetida - piteira',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Dieffenbachia picta - comigo-ninguém-pode',
    paragrafo6b: 'Foto: Maria Clara Forsberg',
    paragrafo7: 'Asparagus densiflorus - aspargo',
    paragrafo7b: 'Foto: Marcelo Vitorino',
    paragrafo8: 'Terminalia catappa - castanheira, amendoeira',
    paragrafo8b: 'Foto: Instituto Horus'
  },
  projetosInstitucional: {
    titulo: 'Proyectos',
    paragrafo1: 'En esta sección encontrará referencias sobre proyectos de investigación, manejo y temas relacionados con especies exóticas invasoras concluidos o en desarrollo. El objetivo es facilitar la comunicación entre personas con intereses en las mismas especies para el intercambio de experiencias, así como para proveer información sobre lo que se está haciendo en una región determinada. ',
    paragrafo2_1: 'Si participa o participo de algún proyecto sobre especies exóticas invasoras, contribuya con la base de datos ',
    paragrafo2_2: ', enviando informação sobre o projeto ',
    paragrafo2_3: 'para que podamos registrarlo. Esperamos que la divulgación beneficie su trabajo.',
    paragrafo3: 'Para citar datos del sistema utilice el siguiente formato:',
    paragrafo4_1: 'Base de Dados Nacional de Especies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo4_2: 'Acessado em __/__/____ (data)'
  },
  referenciasInstitucional: {
    titulo: 'Referencias',
    paragrafo1: 'Las referencias listadas aqui fueron utilizadas para completar la información sobre especies exóticas invasoras em la base de datos, ya sea sobre datos acerca de la biología o de la ecología de las especies, sobre el manejo o las localidades de ocurrencia. Em esta sección tendrá acceso a todas las referencias utilizadas, mientras que em la sección sobre Especies verá solo la lista de referencias utilizadas para la especie seleccionada.',
    paragrafo2: 'Si conoce publicaciones sobre especies exóticas invasoras que no están contempladas aquí, contribuya para la actualización de la Base de Datos Nacional a través del envío de esas referencias.',
    paragrafo3: 'Contribuya con datos',
    paragrafo4: 'Para citar datos del sistema, utilice el siguiente formato:',
    paragrafo5_1: 'Base de Dados Nacional de Especies Exóticas Invasoras. Instituto Hórus de Desenvolvimento e Conservação Ambiental, Florianópolis – SC.',
    paragrafo5_2: 'Consultado en __/__/____ (fecha)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso forestal',
    paragrafo1: 'Varias especies de árboles exóticos se introdujeron en Brasil a partir de la década de 1950 por iniciativa del gobierno para desarrollar la industria forestal con el fin de producir papel, pulpa, madera y otros subproductos. Algunas de estas especies se han convertido en invasoras, y los pinos están especialmente distribuidos en numerosos ambientes. Estas especies requieren una gestión que incluya el control continuo y la contención en las masas forestales para evitar el impacto ambiental y paisajístico. La certificación forestal exige el control de las especies exóticas invasoras y es necesario eliminar estos árboles de las áreas naturales, los bordes de las carreteras, las vías férreas, los bosques de ribera y otros ambientes donde no estén cultivados.',
    paragrafo2: 'El género Pinus se considera el género de plantas con mayor área de invasión en el hemisferio sur. El pino desplaza a las especies nativas en los ecosistemas abiertos y es un gran consumidor de agua, más que las especies nativas.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo4: 'Pínus sp. - pino',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Acacia mearnsii - acacia-negra',
    paragrafo5b: 'Foto: Instituto Horus',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Foto: Instituto Horus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contacto',
    contato: 'Contacto',
    p1: 'Complete todos los campos del formulario siguiente:',
    nome: 'Nombre',
    email: 'Correo electrónico',
    instituicao: 'Institución',
    mensagem: 'Mensaje',
    enviar: 'Enviar'
  }
}
