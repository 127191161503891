<template>
  <div class="container-fluid p-0">
    <PageTitle :title="$t('coralSol.manejos')" :titleAdd="$t('management.addManejo')" :isAdd="true"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <CoralsolManejosTable @orderBy="orderBy" @search="search" :items="items"/>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import CoralsolManejosTable from './CoralsolManejosTable.vue'

export default {
  name: 'CoralsolManejos',
  components: { CoralsolManejosTable, PageTitle },
  data () {
    return {
      items: [],
      dataOrderBy: {}
    }
  },
  methods: {
    clear () {
      this.$store.commit('clearFormSearch')
    },
    orderBy (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy () {
      this.$store.state.formSearch.OrderByIDAcs = this.$store.state.dataOrderBy.OrderByIDAcs
      this.$store.state.formSearch.OrderByIDDesc = this.$store.state.dataOrderBy.OrderByIDDesc
      this.$store.state.formSearch.OrderByManagementCoordsAcs = this.$store.state.dataOrderBy.OrderByManagementCoordsAcs
      this.$store.state.formSearch.OrderByManagementCoordsDesc = this.$store.state.dataOrderBy.OrderByManagementCoordsDesc
      this.$store.state.formSearch.OrderByDateAcs = this.$store.state.dataOrderBy.OrderByDateAcs
      this.$store.state.formSearch.OrderByDateDesc = this.$store.state.dataOrderBy.OrderByDateDesc
      this.$store.state.formSearch.LocalityIDAcs = this.$store.state.dataOrderBy.LocalityIDAcs
      this.$store.state.formSearch.LocalityIDDesc = this.$store.state.dataOrderBy.LocalityIDDesc
      this.$store.state.formSearch.OrderByManagedMassKgAcs = this.$store.state.dataOrderBy.OrderByManagedMassKgAcs
      this.$store.state.formSearch.OrderByManagedMassKgDesc = this.$store.state.dataOrderBy.OrderByManagedMassKgDesc
    },
    async search () {
      this.$store.dispatch('showPreload')

      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      await axios.get('/CoralsolManagement/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    }
  },
  async created () {
    this.$store.state.formSearch.pending_analysis = true
    await this.search()
  }
}
</script>
