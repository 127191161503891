<template>
  <div class="container-fluid p-0">
    <PageTitle :title="$t('coralSol.dafor')" :titleAdd="$t('dafor.addDafor')" :isAdd="true"/>

    <!-- table -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <CoralsolDaforTable @orderBy="orderBy" @search="search" :items="items"/>
          </div>
        </div>
      </div>
    </div>
    <!-- /table -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import CoralsolDaforTable from './CoralsolDaforTable.vue'

export default {
  name: 'CoralsolDafor',
  components: { CoralsolDaforTable, PageTitle },
  data () {
    return {
      items: [],
      dataOrderBy: {}
    }
  },
  methods: {
    clear () {
      this.$store.commit('clearFormSearch')
    },
    orderBy (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy () {
      this.$store.state.formSearch.OrderByIDAcs = this.$store.state.dataOrderBy.OrderByIDAcs
      this.$store.state.formSearch.OrderByIDDesc = this.$store.state.dataOrderBy.OrderByIDDesc
      this.$store.state.formSearch.OrderByDateAcs = this.$store.state.dataOrderBy.OrderByDateAcs
      this.$store.state.formSearch.OrderByDateDesc = this.$store.state.dataOrderBy.OrderByDateDesc
      this.$store.state.formSearch.LocalityIDAcs = this.$store.state.dataOrderBy.LocalityIDAcs
      this.$store.state.formSearch.LocalityIDDesc = this.$store.state.dataOrderBy.LocalityIDDesc
      this.$store.state.formSearch.NumberofvisualtransectsIDAcs = this.$store.state.dataOrderBy.NumberofvisualtransectsIDAcs
      this.$store.state.formSearch.NumberofvisualtransectsIDDesc = this.$store.state.dataOrderBy.NumberofvisualtransectsIDDesc
    },
    async search () {
      this.$store.dispatch('showPreload')

      this.setFormOrderBy()
      const params = this.$store.state.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      await axios.get('/CoralsolDafor/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
        })
    }
  },
  async created () {
    this.$store.state.formSearch.pending_analysis = true
    await this.search()
  }
}
</script>
