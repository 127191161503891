<template>
    <div class="table-responsive full_table mt-0">
      <table class="table caption-top">
        <thead class="bg_table_header">
          <tr>
            <th class="col-md-1 py-3">ID
              <button class="bt_order_asc bt_order" @click="orderBy(1)"></button>
              <button class="bt_order_desc bt_order" @click="orderBy(-1)"></button>
            </th>
            <th class="col-md-2 py-3">{{ $t('dafor.date') }}
              <button class="bt_order_asc bt_order" @click="orderBy(2)"></button>
              <button class="bt_order_desc bt_order" @click="orderBy(-2)"></button>
            </th>
            <th class="col-md-2 py-3">{{ $t('coralSol.localidade') }}
              <button class="bt_order_asc bt_order" @click="orderBy(3)"></button>
              <button class="bt_order_desc bt_order" @click="orderBy(-3)"></button>
            </th>
            <th class="col-md-2 py-3">{{ $t('dafor.numberOfVisualTransects') }}
              <button class="bt_order_asc bt_order" @click="orderBy(4)"></button>
              <button class="bt_order_desc bt_order" @click="orderBy(-4)"></button>
            </th>
            <th class="col-md-2 py-3 pr-4 text-right">{{ $t('label.acoes') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.countList === 0">
            <td colspan="7" class="text-center">{{ $t('message.noRecords') }}</td>
          </tr>
          <tr v-else v-for="item in items" :key="item.dafor_id">
            <td class="py-3">{{ item.dafor_id }}</td>
            <td class="py-3">{{ item.date }}</td>
            <td class="py-3">{{ item.locality_name }}</td>
            <td class="py-3">{{ item.number_of_visual_transects }}</td>
            <td class="py-3">
              <ul class="acoes text-right pr-1">
                <li>
                  <a @click="action($actionEdit, item)">
                    <span class="editar"></span>
                  </a>
                </li>
                <li>
                  <a @click="action($actionDelete, item)" v-if="$store.state.user && ($store.state.user.perfilUser === 'administrator' || $store.state.user.perfilUser === 'coralsoladmin')">
                    <span class="apagar"></span>
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <TablePagination v-on:searchTable="search()"/>
    </div>
</template>
<script>
import axios from 'axios'
import TablePagination from '@/components/shared/TablePagination'

export default {
  name: 'CoralsolDaforTable',
  components: { TablePagination },
  props: {
    items: Array
  },
  data () {
    return {
      dataOrderBy: {}
    }
  },
  methods: {
    action (action, item) {
      if (action === this.$actionDelete) {
        if (confirm(this.$t('message.confirmDel'))) {
          this.delete(item)
        }
      } else {
        this.$router.push({ name: 'CoralsolDaforForm', params: { id: item.dafor_id } })
      }
    },
    delete (item) {
      axios.delete(`/Dafor/${item.dafor_id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess()
          this.search()
        })
    },
    search () {
      this.$emit('search')
    },
    orderBy (num) {
      this.$store.state.dataOrderBy = {}
      if (num === 1) {
        this.$store.state.dataOrderBy.OrderByIDAcs = true
        this.$store.state.dataOrderBy.OrderByIDDesc = !this.$store.state.dataOrderBy.OrderByIDAcs
      }
      if (num === -1) {
        this.$store.state.dataOrderBy.OrderByIDDesc = true
        this.$store.state.dataOrderBy.OrderByIDAcs = !this.$store.state.dataOrderBy.OrderByIDDesc
      }
      if (num === 2) {
        this.$store.state.dataOrderBy.OrderByDateAcs = true
        this.$store.state.dataOrderBy.OrderByDateDesc = !this.$store.state.dataOrderBy.OrderByDateAcs
      }
      if (num === -2) {
        this.$store.state.dataOrderBy.OrderByDateDesc = true
        this.$store.state.dataOrderBy.OrderByDateAcs = !this.$store.state.dataOrderBy.OrderByDateDesc
      }
      if (num === 3) {
        this.$store.state.dataOrderBy.LocalityIDAcs = true
        this.$store.state.dataOrderBy.LocalityIDDesc = !this.$store.state.dataOrderBy.LocalityIDAcs
      }
      if (num === -3) {
        this.$store.state.dataOrderBy.LocalityIDDesc = true
        this.$store.state.dataOrderBy.LocalityIDAcs = !this.$store.state.dataOrderBy.LocalityIDDesc
      }
      if (num === 4) {
        this.$store.state.dataOrderBy.NumberofvisualtransectsIDAcs = true
        this.$store.state.dataOrderBy.NumberofvisualtransectsIDDesc = !this.$store.state.dataOrderBy.NumberofvisualtransectsIDAcs
      }
      if (num === -4) {
        this.$store.state.dataOrderBy.NumberofvisualtransectsIDDesc = true
        this.$store.state.dataOrderBy.NumberofvisualtransectsIDAcs = !this.$store.state.dataOrderBy.NumberofvisualtransectsIDDesc
      }

      this.$emit('orderBy', this.$store.state.dataOrderBy)
    }
  }
}
</script>
