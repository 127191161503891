<template>
  <div class="container-fluid p-0">
    <PageTitle
      :title="pageTitle"
      :titleSave="pendingAnalysis ? $t('localidade.salvar') : $t('localidade.salvar')"
      :isAdd="false"
      @onSave="save"
      @onCancel="cancel"
      @onExcluir="excluir"
      :nameEdit="nameEdit"
      :pendingAnalysis="pendingAnalysis"
      :hasExportCSV="this.$route.params.id !== this.$actionNew"
      @onExportCSV="exportCSV"
    />

    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-3">
            <div class="align-self-center">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="mt-3"
                    :class="{ 'pending_analysis': pendingAnalysis && item.log_update && item.name !== item.log_update.name }"
                  >
                    <label for="locality-name" class="form-label">{{ $t('label.nome') }}</label>
                    <button
                      v-if="pendingAnalysis && item.log_update && item.name !== item.log_update.name"
                      class="btn"
                      type="button"
                      @click="analysis($t('label.nome'), 'name')"
                      data-bs-toggle="modal"
                      data-bs-target="#modalAnalysis"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      id="locality-name"
                      v-model="item.name"
                      :class="{ 'is-invalid': $v.item.name.$dirty && $v.item.name.$error }"
                    />
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="date" class="form-label">{{ $t('label.data') }}</label>
                    <input type="text" class="form-control" v-model="item.date" v-mask="['#####', '##/####', '##/##/####']" />
                  </div>
                  <div class="mt-3">
                    <label for="conservation_unit" class="form-label">{{ $t('localidade.unidadeConservacao') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="conservation_unit"
                      v-model="item.conservation_unit"
                    />
                  </div>
                </div>
                <div class="col-md-6 border-start">
                  <div class="mt-3">
                    <label for="add-ocorrencias-colaborador" class="form-label">{{ $t('label.colaborador') }}</label>
                    <button class="btn" type="button" @click="analysis($t('label.colaborador'), 'relContactsSelected')" data-bs-toggle="modal" data-bs-target="#modalAnalysis">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                    <v-select
                      :appendToBody="true"
                      :filterable="false"
                      @search="getContacts"
                      :options="contactsList"
                      v-model="item.contact_id"
                      label="surname"
                      index="contact_id"
                      :reduce="r => r.contact_id"
                      :clearable="false"
                      class="wf-select vue-select"
                    >
                      <template v-slot:selected-option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:option="option">
                        {{ `${option.surname}, ${option.name}` }}
                      </template>
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                        </template>
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="referencia_geografica" class="form-label">{{ $t('localidade.referenciaGeografica') }}</label>
                    <textarea
                      id="referencia_geografica"
                      class="form-control"
                      v-model="item.referencia_geografica"
                      rows="4"
                      placeholder="Ex: Próximo ao Rio Azul, margem direita..."
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mt-3">
                    <div id="map" ref="mapContainer" style="height: 500px;"></div>
                    <label for="coords_local" class="form-label">{{ $t('localidade.coordinates') }}</label>
                    <textarea class="form-control" id="coords_local" rows="4" v-model="item.coords_local" readonly
                    :class="{ 'is-invalid': $v.item.name.$dirty && $v.item.name.$error }"
                    ></textarea>
                    <div class="invalid-feedback">
                      {{ $t('message.requiredField') }}
                    </div>
                    <button class="btn btn-secondary mt-2 ml-2" @click="undoLastPoint">{{ $t('localidade.undoLastPoint') }}</button>
                    <button class="btn btn-primary  mt-2  ml-2" @click="toggleManualInput">
                      {{ showManualInput ? $t('localidade.cancelar') : $t('localidade.adicionarCoordenada') }}
                    </button>
                    <div v-if="showManualInput" class="d-flex flex-column flex-grow-1">
                      <input
                        v-model="manualCoord"
                        type="text"
                        class="form-control mt-2"
                        placeholder="[[-27.28, -48.35], [-27.29, -48.36]]" />
                      <small class="text-muted">Latitude, Longitude</small>
                      <button class="btn btn-success mt-2 ml-2" @click="addManualCoordinate">{{ $t('localidade.confirmar') }}</button>
                      <span v-if="coordError" class="text-danger mt-2 ml-2">{{ coordError }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminModalReview :form="item" :modalValues="modalValues" />
  </div>
</template>

<script>
import axios from 'axios'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import PageTitle from '@/components/shared/PageTitle'
import AdminModalReview from '@/components/admin/AdminModalReview'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CoralsolLocalidadeForm',
  components: {
    PageTitle,
    AdminModalReview
  },
  data () {
    return {
      pageTitle: `${this.$t('localidade.pageTitle')} > ${this.$t('label.adicionar')}`,
      nameEdit: '',
      action: this.$actionNew,
      item: {
        name: '',
        date: this.$options.filters.formatDate(new Date()),
        contact_id: '',
        coords_local: '',
        referencia_geografica: '',
        conservation_unit: ''
      },
      modalValues: {
        label: '',
        isList: false,
        oldValue: '',
        newValue: ''
      },
      relContactsSelected: [],
      oldRelContactsSelected: [],
      contactsList: [],
      map: null,
      drawnItems: null,
      polyline: null,
      points: [],
      startMarker: null,
      endMarker: null,
      showManualInput: false,
      manualCoord: '',
      coordError: ''
    }
  },
  validations: {
    item: {
      name: { required },
      coords_local: { required },
      contact_id: { required }
    },
    relContactsSelected: { required }
  },
  watch: {
    'item.contact_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getContacts()
      }
    }
  },
  computed: {
    pendingAnalysis () {
      return this.item.pending_analysis && this.$store.state.user.perfilUser === 'administrator'
    },
    pendingRelContacts () {
      let result = false
      if (this.relContactsSelected.length !== this.oldRelContactsSelected.length) {
        return true
      }
      this.oldRelContactsSelected.forEach(e => {
        if (!this.relContactsSelected.includes(e)) {
          result = true
        }
      })
      return result
    }
  },
  methods: {
    modalValuesSimple (newValue, oldValue, label) {
      this.modalValues.label = label
      this.modalValues.isList = false
      this.modalValues.newValue = newValue
      this.modalValues.oldValue = oldValue
    },
    analysis (label, input) {
      let newValue = ''
      let oldValue = ''
      switch (input) {
        case 'name':
          newValue = this.item.name
          oldValue = this.item.log_update ? this.item.log_update.name : ''
          this.modalValuesSimple(newValue, oldValue, label)
          break
        case 'contact_id':
          newValue = this.vocSpecialtiesList.filter(l => l.contact_id === this.item.contact_id)[0]
          oldValue = this.vocSpecialtiesList.filter(l => l.contact_id === this.item.log_update.contact_id)[0]
          this.modalValuesSimple(newValue ? newValue.value : '', oldValue ? oldValue.value : '', label)
          break
      }
    },
    save () {
      this.$v.$touch()
      if (this.$v.item.$invalid) {
        return false
      }
      this.item.log_update = JSON.stringify(this.item.log_update)
      if (this.action === this.$actionEdit) {
        axios.put('/CoralSolLocality', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.item.log_update = JSON.parse(this.item.log_update)
            this.cancel()
          })
      } else {
        axios.post('/CoralSolLocality', this.item)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel () {
      if (this.$store.state.beforeRouter === 'AdminHome') {
        this.$router.replace('/admin')
      } else {
        this.$router.replace('/admin/coralsol/localidade')
      }
    },
    excluir () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/CoralSolLocality/${this.item.localityId}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    },
    exportCSV () {
      const dados = {
        Nome: this.item.name,
        Data: this.item.date,
        'Unidade de Conservação': this.item.conservation_unit,
        Colaborador: this.getContactName(this.item.contact_id),
        'Referência Geográfica': this.item.referencia_geografica,
        Coordenadas: this.item.coords_local
      }

      const headers = Object.keys(dados).join(';')
      const values = Object.values(dados).map(value => typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value).join(';')
      const csv = `${headers}\n${values}`

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'registro_dafor.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getContactName (id) {
      const contact = this.contactsList.find(c => c.contact_id === id)
      return contact ? `${contact.surname}, ${contact.name}` : ''
    },
    getContacts (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.surname = search
        loading(true)
        axios.get('/Contact/getallcombo', { params })
          .then(response => {
            this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
            loading(false)
          })
      } else if (!search) {
        params.contact_id = this.item.contact_id
        if (this.item.contact_id) {
          axios.get('/Contact/getallcombo', { params })
            .then(response => {
              this.contactsList = response.data.items.filter((item, index, self) => index === self.findIndex((t) => t.contact_id === item.contact_id))
            })
        }
      }
    },
    getById (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/CoralSolLocality/${id}`)
        .then(response => {
          this.item = response.data
          if (this.item.name && this.item.name.length > 100) {
            this.nameEdit = `${this.item.name.substring(0, 100)}...`
          }
          if (this.item.contact_id) {
            this.getContacts()
          }
          this.initializeMap()
        })
    },
    initializeMap () {
      if (this.map) {
        return
      }

      this.map = L.map(this.$refs.mapContainer).setView([-27.2833, -48.3683], 13)

      L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 18,
        attribution: 'Tiles © Esri'
      }).addTo(this.map)

      this.drawnItems = L.featureGroup().addTo(this.map)
      if (this.item.coords_local) {
        const coords = JSON.parse(this.item.coords_local)
        this.points = coords
        this.polyline = L.polyline(coords, { color: 'red' }).addTo(this.drawnItems)
        this.updateMarkers()
        const bounds = this.polyline.getBounds()
        this.map.fitBounds(bounds)
      } else {
        this.map.setView([-27.28335582271241, -48.36831414448718], 13)
      }
      this.loadExistingLocalities()
      this.map.on('click', this.onMapClick)
    },
    async loadExistingLocalities () {
      try {
        const response = await axios.get('/CoralSolLocality/getall')
        const localities = response.data.items

        localities.forEach(locality => {
          const coords = JSON.parse(locality.coords_local)
          const polyline = L.polyline(coords, { color: 'blue' }).addTo(this.drawnItems)

          // Adiciona um popup com detalhes da localidade
          polyline.bindPopup(`<strong>${locality.name}</strong><br>${locality.date}`)
        })
      } catch (error) {
        console.error('Erro ao carregar localidades:', error)
      }
    },
    onMapClick (e) {
      const latlng = e.latlng
      this.points.push([latlng.lat, latlng.lng])
      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.coords_local = JSON.stringify(this.points)
    },
    updateMarkers () {
      if (this.startMarker) {
        this.drawnItems.removeLayer(this.startMarker)
      }
      if (this.endMarker) {
        this.drawnItems.removeLayer(this.endMarker)
      }

      if (this.points.length > 0) {
        this.startMarker = L.marker(this.points[0], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }

      if (this.points.length > 1) {
        this.endMarker = L.marker(this.points[this.points.length - 1], {
          icon: L.icon({
            iconUrl: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
          })
        }).addTo(this.drawnItems)
      }
    },
    undoLastPoint () {
      this.points.pop()
      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }
      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.item.coords_local = JSON.stringify(this.points)
    },
    toggleManualInput () {
      this.showManualInput = !this.showManualInput
      this.manualCoord = ''
      this.coordError = ''
    },
    addManualCoordinate () {
      let parsed

      try {
        parsed = JSON.parse(this.manualCoord)

        const isValidFormat = Array.isArray(parsed) &&
          parsed.every(coord =>
            Array.isArray(coord) &&
            coord.length === 2 &&
            typeof coord[0] === 'number' &&
            typeof coord[1] === 'number' &&
            coord[0] >= -90 && coord[0] <= 90 &&
            coord[1] >= -180 && coord[1] <= 180
          )

        if (!isValidFormat) {
          throw new Error()
        }
      } catch (e) {
        this.coordError = 'Formato inválido. Use: [[lat, lng], [lat, lng], ...]'
        return
      }

      // Adiciona os pontos ao array principal
      this.points.push(...parsed)

      if (this.polyline) {
        this.drawnItems.removeLayer(this.polyline)
      }

      this.polyline = L.polyline(this.points, { color: 'red' }).addTo(this.drawnItems)
      this.updateMarkers()
      this.map.fitBounds(this.polyline.getBounds())
      this.item.coords_local = JSON.stringify(this.points)
      this.toggleManualInput()
    }
  },
  mounted () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = `${this.$t('localidade.pageTitle')} > ${this.$t('label.editando')}:`
      this.getById(this.$route.params.id)
    } else {
      this.initializeMap()
    }
    this.getContacts()
  }
}
</script>

<style scoped>
#map {
  height: 500px;
}
</style>
